<template>
  <div class="mt-3">
    <b-card style="border-radius: 20px">
      <b-card-header>
        <span
          class="text-wrap saj-title m-0"
          style="
            letter-spacing: 0.4px;
            color: #000000

          "
        >
          {{ $t("Part") }} 1: {{ $t("Overall Task Assessment") }}. {{ $t("Completed by members and discussed with Assessor") }}.
        </span>
      </b-card-header>

      <hr>
      <div class="m-0 mt-2">
        <b-card-body>
          <span
            class="saj-title m-0 d-flex justify-content-end"
            style="
              letter-spacing: 0.4px;
              color: #000000;
            "
          >
            {{ total_score === null ? 0 : total_score }}/{{ jd_weightage }} %
          </span>
          <br>
          <span
            class="saj-title"
            style="
              letter-spacing: 0.4px;
              color: #000000;
            "
          >
            {{ $t("Summary of tasks performed during the year") }}.
          </span>
          <div class="m-0 mt-1 mb-1">
            <b-form-textarea
              v-model="job_task"
              class="saj-title"
              type="text"
              disabled
              placeholder="text"
              style="height: 200px"
            />
          </div>
          <b-row
            class="
              d-flex
              justify-content-start
              align-items-center
              m-0
              ml-1
              mb-1
              saj-subtitle
            "
            style="
              letter-spacing: 0.1em;
              color: #000000;
              width: 100%;
            "
          >
            <div
              v-if="file === null"
              class="row align-items-center"
              style="font-size: 0.8rem"
            >
              <feather-icon
                class="mr-1"
                icon="PaperclipIcon"
                size="16"
              />
              {{ $t("No File") }}
            </div>
            <div
              v-if="file !== null"
              class="row align-items-center"
              style="font-size: 0.8rem"
            >
              <feather-icon
                class="mr-1"
                icon="PaperclipIcon"
                size="16"
              />
              <a
                :href="file.url"
                target="_blank"
              >
                {{ $t(file.name) }}
              </a>
            </div>
          </b-row>

          <div
            class="self-score"
            style="
              letter-spacing: 0.1em;
              color: #000000;
            "
          >
            {{ $t("Self Score") }}: {{ skor1 === null ? 0 : skor1 }}/6
          </div>
          <!-- star -->
          <div class="score row mb-2 row">
            <div
              class="col-10 d-flex align-items-center self-score"
              style="
                letter-spacing: 0.1em;
                color: #000000;
              "
            >
              {{ $t("Manager Score") }}:

              <b-form-rating
                v-model="value1"
                show-clear
                no-border
                show-value
                color="#DFB300"
                style="margin-left: 5px"
                stars="6"
                size="lg"
                :disabled="disableRating"
                inline
              />
              <span class="m-1">
                <h5 v-if="value1 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                <h5 v-else-if="value1 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                <h5 v-else-if="value1 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                <h5 v-else-if="value1 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                <h5 v-else-if="value1 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                <h5 v-else-if="value1 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
              </span>
            </div>

            <div class="col-2;">
              <input
                id="fileInput"
                ref="file"
                type="file"
                hidden
                @change="handleFileUpload()"
              >
              <!-- <b-button
                id="btn1"
                class="m-1 saj-button"
                variant="primary"
                style=""
              >
                {{ $t("Appendix") }}
              </b-button> -->
              <div v-if="submit_at !== null && (roles.selectedRole === 1 || ((roles.selectedRole === 3 || (roles.selectedRole === 4 && (!division || !section))) && isSupervisor === 1) || roles.selectedRole === 5 || roles.selectedRole === 6)">
                <b-button
                  class="m-1 saj-button"
                  variant="primary"
                  :style="isApproveSV || isApproveHOD || isApproveHR || isApproveSHR ? '' : 'display: none;'"
                  @click="toggleInput1()"
                >
                  {{ $t("Edit") }}
                </b-button>
                <b-button
                  class="m-1 saj-button"
                  style="
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
                  :style="isApproveSV || isApproveHOD || isApproveHR || isApproveSHR ? '' : 'display: none;'"
                  @click="ManagerScore(), toggleInput1()"
                >
                  {{ $t("Save") }}
                </b-button>
              </div>
            </div>
          </div>
          <span class="saj-title">
            {{ $t("Summary of tasks performed during the year") }}/
            {{ $t("Other tasks as directed from time to time") }}.
          </span>
          <div class="m-0 mt-1 mb-1">
            <b-form-textarea
              v-model="job_subtask"
              class="saj-title"
              placeholder="text"
              type="text"
              disabled
              style="height: 200px"
            />
          </div>
          <b-row
            class="
              d-flex
              justify-content-start
              align-items-center
              m-0
              ml-1
              mb-1
              saj-subtitle
            "
            style="
              letter-spacing: 0.1em;
              color: #000000;
              width: 100%;
            "
          >
            <div
              v-if="file2 === null"
              class="row align-items-center"
              style="font-size: 0.8rem"
            >
              <feather-icon
                class="mr-1"
                icon="PaperclipIcon"
                size="16"
              />
              {{ $t("No File") }}
            </div>
            <div
              v-if="file2 !== null"
              class="row align-items-center"
              style="font-size: 0.8rem"
            >
              <feather-icon
                class="mr-1"
                icon="PaperclipIcon"
                size="16"
              />
              <a
                :href="file2.url"
                target="_blank"
              >
                {{ $t(file2.name) }}
              </a>
            </div>
          </b-row>
          <div
            class="self-score"
            style="
              color: #000000;
            "
          >
            {{ $t("Self Score") }}: {{ skor2 === null ? "0" : skor2 }}/6
          </div>
          <!-- star -->
          <div class="row mb-2 row">
            <div
              class="self-score col-10 d-flex align-items-center"
              style="
                color: #000000;
              "
            >
              {{ $t("Manager Score") }}:

              <b-form-rating
                v-model="value2"
                show-clear
                no-border
                show-value
                inline
                color="#DFB300"
                style="margin-left: 5px"
                stars="6"
                size="lg"
                :disabled="disableRating2"
              />
              <span class="m-1">
                <h5 v-if="value2 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                <h5 v-else-if="value2 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                <h5 v-else-if="value2 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                <h5 v-else-if="value2 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                <h5 v-else-if="value2 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                <h5 v-else-if="value2 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
              </span>
            </div>

            <div class="col-2;">
              <input
                id="fileInput"
                ref="file"
                type="file"
                hidden
                @change="handleFileUpload()"
              >
              <div v-if="submit_at !== null && (roles.selectedRole === 1 || ((roles.selectedRole === 3 || (roles.selectedRole === 4 && (!division || !section) )) && isSupervisor === 1) || roles.selectedRole === 5 || roles.selectedRole === 6)">
                <b-button
                  class="m-1 saj-button"
                  variant="primary"
                  :style="isApproveSV || isApproveHOD || isApproveHR || isApproveSHR ? '' : 'display: none;'"
                  @click="toggleInput2()"
                >
                  {{ $t("Edit") }}
                </b-button>
                <b-button
                  class="m-1 saj-button"
                  style="
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
                  :style="isApproveSV || isApproveHOD || isApproveHR || isApproveSHR ? '' : 'display: none;'"
                  @click="ManagerScore(), toggleInput2()"
                >
                  {{ $t("Save") }}
                </b-button>
              </div>
            </div>
          </div>
        </b-card-body>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormTextarea,
  BButton,
  BCardHeader,
  BCardBody,
  BRow,
  // BCol,
  BFormRating,
} from "bootstrap-vue"
// import axios from "axios"
import SAJToast from "@/component/saj-toastification.vue"
import { mapGetters } from 'vuex'

export default {
  components: {
    // BButton,
    // BButtonGroup,
    // BNav,
    // BNavItem,
    // BLink,
    BCard,
    BCardHeader,
    BCardBody,
    BFormTextarea,
    BButton,
    BRow,
    // BCol,
    BFormRating,
  },
  props: {
    division: {
      type: null,
      default: null,
    },
    section: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      user: "",
      id: this.$route.params.id,
      performance_id: this.$route.params.performance_id,
      isSupervisor: null,
      jd_id: "",
      skor1: "",
      skor2: "",
      skor3: "",
      value1: "",
      value2: "",
      value3: "",
      disableInput: true,
      disableRating: true,
      disableInput2: true,
      disableRating2: true,
      disableInput3: true,
      disableRating3: true,
      job_task: "",
      // task_score: "",
      job_subtask: "",
      // subtask_score: "",
      job_othertask: "",
      others: "",
      isSelecting: false,
      selectedFile: null,
      file: "",
      file2: "",
      filename: "",
      total_score: null,
      submit_at: null,
      rating: [],
      approved_at: '',
      approved_sv_at: null,
      agree_at: null,
      approved_hod_at: null,
      approved_hr_at: null,
      isApproveSHR: false,
      isApproveHOD: false,
      isApproveHR: false,
      isApproveSV: false,
      subsidiary_id: null,
      grade_set_id: null,
      jd_weightage: null,
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
    ...mapGetters(['roles', 'userDetails']),
  },
  mounted() {
    // document.getElementById('btn1').addEventListener('click', () => {
    //   document.getElementById('fileInput').click()
    // })
    // document.getElementById('btn2').addEventListener('click', () => {
    //   document.getElementById('fileInput').click()
    // })
    this.getScoreRating()
    this.getData()
    this.currentUser()
    this.checkSupervisorStatus()
    // console.log('user performance id', this.performance_id)
  },
  methods: {
    getScoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll`).then(response => {
        this.rating = response.data.data
      })
    },
    currentUser(){
      const data = new FormData()
      // data.append("user_id", this.idCore)
      this.$axios.get(`${this.$baseUrl}/supervisor/getEmployee?user_id=${this.id}`, data).then(response => {
        // console.log(response)
        this.user = response.data.data.role_name
        this.subsidiary_id = response.data.data.employee.business_unit
        this.grade_set_id = response.data.data.grade_set_id
        // console.log(this.subsidiary_id, this.grade_set_id)
        // console.log("role", this.user)
        this.weightage()
      })
    },
    weightage(){
      const params = new URLSearchParams()
      params.append('subsidiary_id', this.subsidiary_id)
      params.append('role_id', this.grade_set_id)

      this.$axios.get(`${this.$baseUrl}/weightage/get_role_percentage`, { params }).then(response => {
        // console.log(response)
        this.jd_weightage = response.data.data.overall_assesment
      })
    },
    checkSupervisorStatus(){
      this.$axios.get(`${this.$baseUrl}/supervisor/checkSupervisory?performance_id=${this.performance_id}&user_id=${this.userDetails.user_id}`).then(response => {
        this.isSupervisor = response.data.data.status.is_supervisor
        // this.isAppraiserSv = response.data.data.status.is_appraiser_sv
        // console.log("ini sv ke", this.isSupervisor)
        // console.log("ini appraiser sv ke", this.isAppraiserSv)
      })
    },
    toggleInput1() {
      this.disableInput = !this.disableInput
      this.disableRating = !this.disableRating
    },
    toggleInput2() {
      this.disableInput2 = !this.disableInput2
      this.disableRating2 = !this.disableRating2
    },
    toggleInput3() {
      this.disableInput3 = !this.disableInput3
      this.disableRating3 = !this.disableRating3
    },
    ManagerScore() {
      const data = new FormData()
      data.append("user_id", this.id)
      data.append("manager_score", this.value1)
      data.append("manager_subscore", this.value2)
      data.append(`current_role`, this.roles.selectedRole)

      this.$axios
        .post(`${this.$baseUrl}/manager/jd_manager_score_store`, data)
        .then(() => {
          // if (response.data.success === true){
          //   data.append('', this.profile.full_name)
          // } else {
          //   // this.$errorConfirmation()
          //   this.errorConfirmation('danger')
          // }
          // console.log(this.file)
          // console.log(response)
          setTimeout(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Manager Score successfully updated')}.`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  titleColor: "#000",
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
          }, 1000)
        })
    },
    getData() {
      const data = new FormData()
      this.$axios
        .get(
          `${this.$baseUrl}/myperformances/job_description?user_id=${this.id}`,
          data,
        )
        .then(response => {
          // console.log(response)
          this.submit_at = response.data.data.submit_at
          if (response.data.data.job_description !== null){
            this.jd_id = response.data.data.job_description.id
            this.job_task = response.data.data.job_description.job_task
            this.skor1 = response.data.data.job_description.task_score
            this.value1 = response.data.data.job_description.manager_score
            this.job_subtask = response.data.data.job_description.job_subtask
            this.skor2 = response.data.data.job_description.subtask_score
            this.value2 = response.data.data.job_description.manager_subscore
            this.job_othertask = response.data.data.job_description.job_othertask

            this.total_score = response.data.data.total_score
            this.file = response.data.data.job_description.file
            this.file2 = response.data.data.job_description.file_subtask

            this.approved_at = response.data.data.approved_at // SHR approved
            this.approved_sv_at = response.data.data.approved_sv_at // SV approved
            this.agree_at = response.data.data.agree_at // Employee Agree
            this.approved_hod_at = response.data.data.approved_hod_at // HOD approved
            this.approved_hr_at = response.data.data.approved_hr_at // HR approved

            switch (this.roles.selectedRole){
              case 1:
              // Admin
              // console.log('admin')
                if (this.agree_at !== null && this.approved_at === null){
                // console.log('masuk admin')
                  this.isApproveSHR = true
                }
                break
              case 3:
              // Executive
              // console.log('Executive')
                if (this.agree_at === null){
                  // console.log('masuk exec')
                  this.isApproveSV = true
                }
                break
              case 4:
              // Top Management
              // console.log('Top Management')
                if (this.agree_at === null){
                // console.log('masuk tm')
                  this.isApproveSV = true
                }
                break
              case 5:
              // Head of Department
              // console.log('Head of Department')
                if (this.agree_at !== null && this.approved_hod_at === null){
                // console.log('masuk hod')
                  this.isApproveHOD = true
                }
                break
              case 6:
                // Human Resource
                // console.log('Human Resource')
                if (this.agree_at !== null && this.approved_hr_at === null){
                  // console.log('masuk hod')
                  this.isApproveHR = true
                }
                break
              default:
                break
            }
          }
        })
    },
  },
}
</script>
<style>

.score {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #000000;
}
</style>
