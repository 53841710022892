<template>
  <transition>
    <div class="">
      <div
        class="modal d-flex position-fixed"
        role="dialog"
        aria-describedby="modalDescription"
        style="
          width: 900px;
          height: 800px;
          left:55vh;
          background: #ffffff;
          border-radius: 6px;
          box-shadow: 2px 2px 20px 1px;
          overflow: scroll;
          position: fixed;
        "
      >
        <section
          id="modalDescription"
          class="modal-body"
        >
          <div
            class="
              d-flex
              justify-content-start
              font-weight-bolder
              mb-1
              mt-1
              ml-2
              pl-1
            "
            style="
              <!-- background:lightblue; -->
              font-family: Poppins;
                              font-weight:400;
                              font-style: normal;
                              font-size: 24px;
                              color:black ;
            "
          >
            Core Competencies
            <!-- <button
              class="btn btn-close d-flex "
              aria-label="Close modal"
              @click="close"
            >
              X
            </button> -->
          </div>
          <div
            class="d-flex justify-content-center"
            style="
              <!-- background:lightgreen; -->
            "
          >
            <div class="container">
              <b-col>
                <b-row>
                  <b-card-body class="">
                    <div
                      class=""
                      style="
                      <!-- background:lightpurple -->
                      "
                    >
                      <b-row>
                        <b-col
                          cols="4"
                          class="justify-content-start"
                          style="
                          <!-- background:#66ff66; -->
                          "
                        >
                          <div class="d-flex align-items-start">
                            <h3
                              class="font-weight-bolder"
                              style="
                                font-family: Poppins;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 22px;
                                color: black;
                              "
                            >
                              {{ teras }}
                            </h3>
                          </div>
                        </b-col>
                        <b-col
                          cols="8"
                          class=""
                          style="
                          <!-- background:#66ccff; -->
                          "
                        >
                          <div class="">
                            <h3
                              class="font-weight-bolder"
                              style="
                                font-family: Poppins;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 20px;
                                color: black;
                                align-items: start;
                              "
                            >
                              {{ description }}
                            </h3>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-row>
                <b-row>
                  <div class="d-flex align-items-start ml-2">
                    <h3
                      class=""
                      style="
                        font-family: Poppins;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 20px;
                        color: #464e5f;
                      "
                    >
                      Choose two (2) of the following items:
                    </h3>
                  </div>
                </b-row>
                <b-row>
                  <b-card-body class="">
                    <div
                      v-for="(item, index) in subcore"
                      :key="index"
                      class="
                        employee-task
                        d-flex
                        justify-content-between
                        align-items-start
                      "
                    >
                      <b-row>
                        <b-col
                          cols="1"
                          class="justify-content-start"
                          style="
                          <!-- background:red -->
                          "
                        >
                          <div class="d-flex align-items-center">
                            <b-form-checkbox
                              v-model="selected"
                              name="checkbox-1"
                              label="id of product"
                              type="checkbox"
                              :value="item.id"
                              checked
                            />
                            <!-- @click="item.selected = !item.selected" -->
                          </div>
                        </b-col>
                        <b-col
                          cols="3"
                          class="justify-content-start"
                          style="
                          <!-- background:yellow -->
                          "
                        >
                          <div class="d-flex align-items-start">
                            <h3
                              class="font-weight-bolder"
                              style="
                                font-family: Poppins;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 22px;
                                color: #464e5f;
                              "
                            >
                              {{ item.name }}
                            </h3>
                          </div>
                        </b-col>
                        <b-col
                          cols="8"
                          class=""
                          style="
                          <!-- background:green -->
                          "
                        >
                          <div
                            class="
                              d-flex
                              align-items-start
                              justify-content-between
                            "
                          >
                            <h3
                              class=""
                              style="
                                font-family: Poppins;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 20px;
                                color: #464e5f;
                              "
                            >
                              {{ item.description }}
                            </h3>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-row>
              </b-col>
            </div>
          </div>

          <div
            class="d-flex justify-content-center"
            style="
              <!-- background:pink; -->
            "
          >
            <button
              class="btn btn-close m-1"
              aria-label="Close modal"
              style="
                font-family: Poppins;
                font-weight: 400;
                font-style: normal;
                font-size: 20px;
                color: white;
                background: #ff0000;
              "
              @click="close()"
            >
              Back
            </button>
            <button
              class="btn m-1"
              aria-label="submit modal"
              style="
                font-family: Poppins;
                font-weight: 400;
                font-style: normal;
                font-size: 20px;
                color: white;
                background: #0b103c !important;
                border-color: #0b103c !important;
              "
              @click="toggleinputAdd(), AssignCore()"
            >
              Save
            </button>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  // BAvatar,
  // BContainer,
  // BModal,
  BCol,
  BRow,
  // BTable,
  BFormCheckbox,
  // BButton,
  BCardBody,

} from "bootstrap-vue"
import axios from 'axios'

export default {
  components: {
    // BAvatar,
    // BContainer,
    // BModal,
    BCol,
    BRow,
    // BTable,
    BFormCheckbox,
    // BButton,
    BCardBody,
  },
  props: {
    subcore: {
      type: Array,
      default: () => [
        {
          name: 'Ketetapan Masa',
          description: 'Ketepatan waktu dalam menyelesaikan tugas yang diberi tepat pada masanya.',
        },
        {
          name: 'Rendah Diri',
          description: 'Merendah diri, tidak membangga diri dan tidak angkuh.',
        },
      ],
    },

    teras: {
      type: String,
      default: "Nama Teras",
    },
    description: {
      type: String,
      default: "Maklumat Teras",
    },
  },

  data() {
    return {
      id: this.$route.params.id,
      selected: [],
      name: 'Modal',
      disableInputAdd: true,
      status: 'not_accepted',
      subcore_array: "",
      ItemData: [
        {
          ItemName: 'Ketetapan Masa',
          Description: 'Ketepatan waktu dalam menyelesaikan tugas yang diberi tepat pada masanya.',
        },
        {
          ItemName: 'Rendah Diri',
          Description: 'Merendah diri, tidak membangga diri dan tidak angkuh.',
        },
        {
          ItemName: 'Go Green',
          Description: 'Go Green dengan komited terhadap inisiatif 3R (Mengurangkan, Menggunakan Semula dan Kitar Semula), amalan penjimatan tenaga dan pemuliharaan alam sekitar untuk membantu mengurangkan pencemaran yang disebabkan oleh operasi perniagaan syarikat.',
        },
        {
          ItemName: 'Integriti',
          Description: 'Integriti apabila individu mengambil tanggungjawab peribadi dan akauntabiliti untuk mematuhi peraturan dan peraturan syarikat.',
        },
        {
          ItemName: 'Kerja Berpasukan',
          Description: 'Kerja berpasukan dengan bekerja secara kerjasama, merentasi budaya dan sempadan organisasi untuk mencapai matlamat yang dikongsi.',
        },
      ],
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close')
    },
    toggleinputAdd() {
      this.disableInputAdd = !this.disableInputAdd
    },
    AssignCore() {
      // console.log("selected", this.selected)
      // this.subcore_array = this.selected.split(",")
      // console.log("user_id", this.subcore_array)
      const data = new FormData()
      data.append("user_id", this.id)
      // data.append("subcore_id[]", this.subcore_array[0])
      // data.append("subcore_id[]", this.subcore_array[1])
      this.selected.forEach(element => {
        data.append("subcore_id[]", element)
      })

      // data.append("user_id", this.id)
      // const jobData0 = response.data.data.active_competency_core_performance[0]
      // data.append('self_score', this.self_score[jobData0.default_subcore.core_id])

      axios
        .post(`${this.$baseUrl}/myperformances/assign_performance_core`, data)
        .then(() => {
          // if (response.data.success === true){
          //   data.append('', this.profile.full_name)
          // } else {
          //   // this.$errorConfirmation()
          //   this.errorConfirmation('danger')
          // }

          setTimeout(() => {
            this.$emit('close')
            this.$emit('select-core')
          }, 1000)
        })
      this.selected.splice(0)
    },
  },
}
</script>

<style>
</style>
