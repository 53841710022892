<template>
  <div class="mt-3">
    <section id="card-navigation">
      <b-row>
        <!-- pill tabs inside card -->
        <b-col md="12">
          <b-card
            class="text-center"
            style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
             height:auto
            "
          >
            <b-card-header>
              <span
                class="saj-title m-0"
                style="
                  letter-spacing: 0.4px;
                  color: #000000;
                "
              >
                {{ $t("Part 3") }}
              </span>
            </b-card-header>

            <hr>
            <b-tabs
              nav-class="saj-title m-0"
              style="
                line-height: 24px;
                letter-spacing: 0.1em;
              "
            >
              <!-- Latihan dan Pembangunan -->
              
              <b-tab
                :title="$t('3a. Training and Development')"
                active
              >
              
                <!-- <div
                  v-if="existitems.length !== 0"
                  class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end"
                  style="
                    line-height: 30px;
                    letter-spacing: 0.4px;
                    color: #000000;
                  "
                > -->
                  <!-- Top Management/Senior Management -->
                  <div
                    v-if="grade_set_id === 1 || grade_set_id === 2"
                    class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end"
                  >
                    {{ total_score1 !== null ? total_score1 : 0 }}/{{ total_weight_tm }} %
                  </div>
                  <div
                    v-else
                    class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end"
                  >
                    {{ total_score1 !== null ? total_score1 : 0 }}/{{ training_weightage }} %
                  </div>
                <!-- </div>
                <div
                  v-else
                  class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end"
                  style="
                    line-height: 30px;
                    letter-spacing: 0.4px;
                    color: #000000;
                  "
                > -->
                  <!-- NO Training -->
                <!-- </div> -->
                <div v-if="submit_at !== null && (roles.selectedRole === 1 || ((roles.selectedRole === 3 || (roles.selectedRole === 4 && (!division || !section))) && isSupervisor === 1) || roles.selectedRole === 5 || roles.selectedRole === 6)">
                  <b-col
                    v-if="existitems.length !== 0"
                    style="display: flex; justify-content: flex-end"
                  >
                    <b-button
                      variant="primary"
                      style="margin-right: 10px"
                      :style="isApproveSV || isApproveHOD || isApproveHR || isApproveSHR ? '' : 'display: none;'"
                      class="saj-button"
                      @click="toggleInput1()"
                    >
                      {{ $t("Edit") }}
                    </b-button>
                    <b-button
                      style="
                        margin-right: 9px;
                        background: #0b103c !important;
                        border-color: #0b103c !important;"
                      class="saj-button"
                      :disabled="!isEdit"
                      :style="isApproveSV || isApproveHOD || isApproveHR || isApproveSHR ? '' : 'display: none;'"
                      @click="
                        ManagerScoreTraining(), toggleInput1()
                      "
                    >
                      {{ $t("Save") }}
                    </b-button>
                  </b-col>
                </div>

                <div v-if="existitems.length !== 0">
                  <div
                    class="saj-text m-0 ml-1 mt-2 mb-2 p-0"
                    style="
                      line-height: 24px;
                      letter-spacing: 0.4px;
                      color: #000000;
                      text-align: start !important;
                    "
                  >
                    {{ $t("List The Program and Training That Involved during the evaluation period") }}.
                  </div>

                  <div class="m-2">
                    <b-form
                      ref="form"
                      :style="{ height: trHeight }"
                      class="repeater-form"
                      @submit.prevent="repeateAgain"
                    >
                      <!-- Row Loop -->
                      <b-row
                        v-for="(existitem, i) in existitems"
                        :id="existitem.id"
                        :key="'a' + i"
                        ref="row"
                        class="saj-title m-0 p-2 mb-2"
                        style="
            color: black;
            background: #f7f7fc;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 0px 41px 9px rgba(0, 0, 0, 0.11);
            border-radius: 20px;
            height: 100%;
          "
                      >
                        {{ i + 1 + '.' }}
                        <!-- Tajuk Kursus / Program -->
                        <div class="saj-title col-md-4">
                          <b-form-group
                            label-size="lg"
                            :label="$t('Program Title')"
                            label-for="tajuk-kursus"
                          >
                            <!-- {{ existitem.self_learn }} -->
                            <b-form-input
                              v-model="existitem.program_name"
                              class="saj-text m-1"
                              type="text"
                              disabled
                              placeholder=""
                            />
                          </b-form-group>
                        </div>

                        <!-- Tarikh Mula -->
                        <div class="saj-title col-md-2 ">
                          <b-form-group
                            label-size="lg"
                            :label="$t('Start Date')"
                            label-for="mula"
                          >
                            <flat-pickr
                              v-model="existitem.start_date"
                              class="form-control saj-text m-1 bg-light"
                              :config="{ dateFormat: 'Y-m-d' , altInput: true, altFormat: 'd-m-Y'}"
                              disabled
                            />
                          </b-form-group>
                        </div>

                        <!-- Tarikh Akhir -->
                        <div class="saj-title col-md-2 ">
                          <b-form-group
                            label-size="lg"
                            :label="$t('End Date')"
                            label-for="akhir"
                          >
                            <flat-pickr
                              v-model="existitem.end_date"
                              class="form-control saj-text m-1 bg-light"
                              :config="{ dateFormat: 'Y-m-d' , altInput: true, altFormat: 'd-m-Y'}"
                              disabled
                            />
                          </b-form-group>
                        </div>

                        <!-- Number of hours -->
                        <div class="saj-title col-md-3 ">
                          <b-form-group
                            label-size="lg"
                            :label="$t('Number of hours')"
                            label-for="jumlah-jam"
                          >
                            <b-form-input
                              v-model="existitem.hour"
                              class="saj-text m-1"
                              type="number"
                              disabled
                              placeholder=""
                            />
                          </b-form-group>
                        </div>
                        <!-- Self Score Summary Comment -->
                        <div
                          v-if="existitem.self_learn === 1"
                          class="col-md-11 mr-1"
                          style="margin-left: 27px"
                        >
                          <b-form-group
                            label-size="lg"
                            :label="$t('Summary Comment')"
                            label-for="komen"
                          >
                            <b-form-input
                              v-model="existitem.summary_comment"
                              class="saj-text"
                              type="text"
                              placeholder=""
                              :disabled="disableInput4"
                            />
                          </b-form-group>
                        </div>
                        <b-row
                          class="
                            saj-text
                            d-flex
                            justify-content-start
                            align-items-center
                            m-0
                            ml-3
                          "
                          style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                        >
                          <div
                            v-if="existitem.file === null"
                            class="saj-text row align-items-center"
                            style=""
                          >
                            <feather-icon
                              class="mr-1"
                              icon="PaperclipIcon"
                              size="16"
                            />
                            {{ $t("No File") }}
                          </div>
                          <div
                            v-if="existitem.file !== null"
                            class="saj-text row align-items-center"
                            style=""
                          >
                            <feather-icon
                              class="mr-1"
                              icon="PaperclipIcon"
                              size="16"
                            />
                            <a
                              :href="existitem.file.url"
                              target="_blank"
                            >
                              {{ $t(existitem.file.name) }}
                            </a>
                          </div>
                        </b-row>
                        <b-row
                          class="
                            self-score
                            d-flex
                            justify-content-end
                            align-items-center
                            m-0
                            mr-3
                          "
                          style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                        >
                          {{ $t("Self Score") }}: {{ existitem.skor }}/6
                        </b-row>
                        <b-row
                          class="
                            self-score
                            d-flex
                            justify-content-end
                            align-items-center
                            m-0
                          "
                          style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                        >
                          {{ $t("Manager Score") }}:

                          <b-form-rating
                            v-model="existitem.value1"
                            show-clear
                            style="background: none !important"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating1"
                          />

                          <span style="margin-top: 5px; margin-right: 15px">
                            <h5 v-if="existitem.value1 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                            <h5 v-else-if="existitem.value1 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                            <h5 v-else-if="existitem.value1 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                            <h5 v-else-if="existitem.value1 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                            <h5 v-else-if="existitem.value1 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                            <h5 v-else-if="existitem.value1 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                          </span>
                        </b-row>

                        <b-col cols="12">
                          <hr>
                        </b-col>
                      </b-row>
                    </b-form>
                  </div>
                  <div class="m-2">
                    <b-form
                      ref="form"
                      :style="{ height: trHeight }"
                      class="repeater-form"
                      @submit.prevent="repeateAgain"
                    >
                      <b-col
                        class="saj-title"
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 400;
                          line-height: 24px;
                          color: #000000;
                          display: flex;
                          justify-content: flex-end;
                        "
                      ><b-row
                        class="saj-title"
                        style="display: flex; align-content: center"
                      >
                        {{ $t("Total number of hours") }}</b-row><b-row
                        cols="2"
                        style="
                            width: 15%;
                            margin-right: 8px;
                            display: flex;
                            justify-content: flex-end;
                          "
                      >
                        <b-form-input
                          id="jumlah-jam"
                          v-model="total_hour"
                          class="saj-text m-1"
                          type="number"
                          placeholder=""
                          disabled
                        />
                      </b-row>
                      </b-col>
                    </b-form>
                  </div>
                </div>
                <div
                  v-else
                  class="saj-text d-flex align-items-center justify-content-center p-1 m-0 mb-3 mr-1"
                  style="font-style: italic;  text-align: center; height: 200px; border-radius:20px; background: #f7f7fc; "
                >
                  {{ $t('No Training Record') }}

                </div>

              </b-tab>
              <!--Internal/industry involvement-->

              <b-tab
                v-if="isStatus"
                :title="$t('3b. Internal / Industrial Involvement')"
              >
                <div v-if="existitems2.length !== 0 || existitems3.length !== 0">
                  <div
                    class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end"
                  >
                    {{ total_score2 }}/{{ internal_weightage }} %
                  </div>
                  <div
                    class="saj-text m-0 ml-1 mt-2 p-0"
                    style="
                      font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 400;
                      line-height: 30px;
                      letter-spacing: 0.4px;
                      color: #000000;
                      text-align: start !important;
                    "
                  >
                    {{ $t("List the involvement of internal/industry involvement in any association or body recognized by the laws and regulations of Malaysia") }},
                    {{ $t("except political parties") }}.
                  </div>
                </div>
                <!-- Box 2 -->
                <b-col>
                  <b-card
                    class="mb-1 mt-1"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <div v-if="existitems3.length !== 0">
                      <b-form
                        ref="form"
                        v-model="tambah"
                        :style="{ height: trHeight }"
                        class="repeater-form"
                        @submit.prevent="repeateAgain3"
                      >
                        <!-- Row Loop -->
                        <b-row
                          v-for="(existitem3, i) in existitems3"
                          :id="existitem3.id"
                          :key="'c' + i"
                          ref="row"
                        >
                          <div class="ml-1">
                            {{ i + 1 + '.' }}
                          </div>
                          <!-- Senarai Penglibatan -->
                          <b-col
                            class="saj-title"
                            md="4"
                          >
                            <b-form-group
                              label-size="lg"
                              :label="$t('Involvement Activities')"
                              label-for="senarai-penglibatan"
                            >
                              <b-form-input
                                v-model="existitem3.involvement"
                                class="saj-text m-1"
                                type="text"
                                :disabled="disableInput9"
                                placeholder=""
                              />
                            </b-form-group>
                          </b-col>

                          <!-- Tarikh Penglibatan -->
                          <b-col
                            class="saj-title"
                            md="2"
                          >
                            <b-form-group
                              label-size="lg"
                              :label="$t('Date')"
                              label-for="tarikh penglibatan"
                            >
                              <flat-pickr
                                v-model="existitem3.date"
                                class="form-control bg-white saj-text m-1"
                                :config="{ dateFormat: 'Y-m-d' , altInput: true, altFormat: 'd-m-Y'}"
                                :disabled="disableInput10"
                              />
                            </b-form-group>
                          </b-col>

                          <!-- Jawatan -->
                          <b-col
                            class="saj-title"
                            md="5"
                          >
                            <b-form-group
                              label-size="lg"
                              :label="$t('Position / Level of Participants')"
                              label-for="jawatan"
                            >
                              <b-form-select
                                v-model="existitem3.position"
                                class="saj-text m-1"
                                :options="jawatanoptions2"
                                :disabled="disableInput11"
                              />
                            </b-form-group>
                          </b-col>
                          <b-row
                            class="
                                saj-text
                                d-flex
                                justify-content-start
                                align-items-center
                                m-0
                                ml-3
                              "
                            style="
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 600;
                                line-height: 24px;
                                letter-spacing: 0.1em;
                                color: #000000;
                                width: 100%;
                              "
                          >
                            <div
                              v-if="existitem3.file === null"
                              class="saj-text row align-items-center"
                              style=""
                            >
                              <feather-icon
                                class="mr-1"
                                icon="PaperclipIcon"
                                size="16"
                              />
                              {{ $t("No File") }}
                            </div>
                            <div
                              v-else
                              class="saj-text row align-items-center"
                              style=""
                            >
                              <feather-icon
                                class="mr-1"
                                icon="PaperclipIcon"
                                size="16"
                              />
                              <a
                                :href="existitem3.file.url"
                                target="_blank"
                              >
                                {{ $t(existitem3.file.name) }}
                              </a>
                            </div>
                          </b-row>
                          <b-row
                            class="
                                self-score
                                d-flex
                                justify-content-end
                                align-items-center
                                m-0
                                mr-3
                              "
                            style="
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 600;
                                line-height: 24px;
                                letter-spacing: 0.1em;
                                color: #000000;
                                width: 100%;
                              "
                          >
                            {{ $t("Self Score") }}:
                            <!-- {{ existitem3.skor2 }} -->
                            {{ getUserSelfScore2(existitem3.position) }}
                            /6
                          </b-row>
                          <b-col cols="12">
                            <hr>
                          </b-col>
                        </b-row>
                      </b-form>
                    </div>
                    <div
                      v-else
                      class="saj-text d-flex align-items-center justify-content-center"
                      style="font-style: italic;  text-align: center; height: 150px; "
                    >
                      {{ $t('No Internal / Industrial Involvement Record') }}

                    </div>
                  </b-card>
                </b-col>
              </b-tab>

              <!-- Lain - lain sumbangan-->
              <b-tab
                v-if="false"
                :title="$t('3b. Internal / Industrial Involvement')"
              >
                <div v-if="existitems2.length !== 0 || existitems3.length !== 0">
                  <div
                    class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end"
                  >
                    {{ total_score2 }}/10 %
                  </div>
                  <div
                    class="saj-text m-0 ml-1 mt-2 p-0"
                    style="
                      font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 400;
                      line-height: 30px;
                      letter-spacing: 0.4px;
                      color: #000000;
                      text-align: start !important;
                    "
                  >
                    {{ $t("List the involvement of internal and external contributions Involvement in any association or body recognized by the laws and regulations of Malaysia") }},
                    {{ $t("except political parties") }}.
                  </div>
                </div>
                <div v-else />
                <b-row
                  class=""
                  style="
                      letter-spacing: 0.4px;
                      color: #000000;
                      padding-top: 27px;
                    "
                >
                  <b-col
                    cols=""
                    class=""
                  >
                    <h1
                      class="saj-title mt-1 ml-1 d-flex justify-content-start align-items-center"
                      style="font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 700;
                      line-height: 16px;"
                    >
                      {{ $t("EXTERNAL CONTRIBUTIONS") }}
                      <feather-icon
                        :icon="showExternal !== true ? 'ChevronDownIcon' : 'ChevronUpIcon'"
                        size="26"
                        class="ml-1"
                        @click="showExternal = !showExternal"
                      />
                    </h1>
                  </b-col>
                  <b-col
                    cols=""
                    class="justify-content-end"
                  >
                    <div
                      v-if="existitems2.length !== 0"
                      class="saj-title mt-1 mr-2 mb-1 d-flex justify-content-end"
                      style="
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 30px;
                    letter-spacing: 0.4px;
                    color: #000000;
                  "
                    >
                      {{ external_score }}/7 %
                    </div>
                    <div v-else />
                  </b-col>
                </b-row>
                <!-- Box 1 -->
                <b-col v-show="showExternal">
                  <b-card
                    class="mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <div v-if="existitems2.length !== 0">
                      <b-form
                        ref="form"
                        responsive
                        :style="{ height: trHeight }"
                        class="repeater-form"
                        @submit.prevent="repeateAgain2"
                      >
                        <!-- Row Loop -->
                        <b-row
                          v-for="(existitem2, i) in existitems2"
                          :id="existitem2.id"
                          :key="'b' + i"
                          ref="row"
                        >
                          <div class="ml-1">
                            {{ i + 1 + '.' }}
                          </div>
                          <!-- Senarai Penglibatan -->
                          <b-col
                            class="saj-title"
                            md="4"
                          >
                            <b-form-group
                              label-size="lg"
                              :label="$t('Contribution Activities')"
                              label-for="senarai-penglibatan"
                            >
                              <b-form-input
                                v-model="existitem2.involvement"
                                class="saj-text m-1"
                                type="text"
                                :disabled="disableInput5"
                                placeholder=""
                              />
                            </b-form-group>
                          </b-col>

                          <!-- Tarikh Penglibatan -->
                          <b-col
                            class="saj-title"
                            md="2"
                          >
                            <b-form-group
                              label-size="lg"
                              :label="$t('Date')"
                              label-for="tarikh penglibatan"
                            >
                              <flat-pickr
                                v-model="existitem2.date"
                                class="form-control bg-white saj-text m-1"
                                :disabled="disableInput6"
                                :config="{ dateFormat: 'Y-m-d' , altInput: true, altFormat: 'd-m-Y'}"
                              />
                            </b-form-group>
                          </b-col>

                          <!-- Jawatan -->
                          <b-col
                            class="saj-title"
                            md="5"
                          >
                            <b-form-group
                              label-size="lg"
                              :label="$t('Position / Level of Participants')"
                              label-for="jawatan"
                            >
                              <b-form-select
                                v-model="existitem2.position"
                                class="saj-text m-1"
                                :options="jawatanoptions1"
                                :disabled="disableInput7"
                              />
                            </b-form-group>
                          </b-col>
                          <b-row
                            class="
                                saj-text
                                d-flex
                                justify-content-start
                                align-items-center
                                m-0
                                ml-3
                              "
                            style="
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 600;
                                line-height: 24px;
                                letter-spacing: 0.1em;
                                color: #000000;
                                width: 100%;
                              "
                          >
                            <div
                              v-if="existitem2.file === null"
                              class="saj-text row align-items-center"
                              style=""
                            >
                              <feather-icon
                                class="mr-1"
                                icon="PaperclipIcon"
                                size="16"
                              />
                              {{ $t("No File") }}
                            </div>
                            <div
                              v-if="existitem2.file !== null"
                              class="saj-text row align-items-center"
                              style=""
                            >
                              <feather-icon
                                class="mr-1"
                                icon="PaperclipIcon"
                                size="16"
                              />
                              <a
                                :href="existitem2.file.url"
                                target="_blank"
                              >
                                {{ $t(existitem2.file.name) }}
                              </a>
                            </div>
                          </b-row>
                          <b-row
                            class="
                                self-score
                                d-flex
                                justify-content-end
                                align-items-center
                                m-0
                                mr-3
                              "
                            style="
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 600;
                                line-height: 24px;
                                letter-spacing: 0.1em;
                                color: #000000;
                                width: 100%;
                              "
                          >
                            <!-- Member Self Score: {{ existitem2.skor1 }} -->
                            {{ $t("Self Score") }}:
                            {{ getUserSelfScore(existitem2.position) }}
                            /6
                          </b-row>
                          <b-col cols="12">
                            <hr>
                          </b-col>
                        </b-row>
                      </b-form>
                    </div>
                    <div
                      v-else
                      class="saj-text d-flex align-items-center justify-content-center"
                      style="font-style: italic;  text-align: center; height: 150px; "
                    >
                      {{ $t('No External Contributions Record') }}

                    </div>
                  </b-card>
                </b-col>
                <!-- Sumbangan Dalaman -->
                <b-row
                  class=""
                  style="
                      letter-spacing: 0.4px;
                      color: #000000;
                      padding-top: 27px;
                    "
                >
                  <b-col
                    cols=""
                    class=""
                  >
                    <h1
                      class="saj-title mt-1 ml-1 d-flex justify-content-start align-items-center"
                      style="font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 700;
                      line-height: 16px;"
                    >
                      {{ $t("INTERNAL CONTRIBUTIONS") }}
                      <feather-icon
                        :icon="showInternal !== true ? 'ChevronDownIcon' : 'ChevronUpIcon'"
                        size="26"
                        class="ml-1"
                        @click="showInternal = !showInternal"
                      />
                    </h1>
                  </b-col>
                  <b-col
                    cols=""
                    class="justify-content-end"
                  >
                    <div
                      v-if="existitems3.length !== 0"
                      class="saj-title mt-1 mr-2 mb-1 d-flex justify-content-end"
                      style="
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 30px;
                    letter-spacing: 0.4px;
                    color: #000000;
                  "
                    >
                      {{ internal_score }}/3 %
                    </div>
                    <div v-else />
                  </b-col>
                </b-row>
                <!-- Box 2 -->
                <b-col v-show="showInternal">
                  <b-card
                    class="mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <div v-if="existitems3.length !== 0">
                      <b-form
                        ref="form"
                        v-model="tambah"
                        :style="{ height: trHeight }"
                        class="repeater-form"
                        @submit.prevent="repeateAgain3"
                      >
                        <!-- Row Loop -->
                        <b-row
                          v-for="(existitem3, i) in existitems3"
                          :id="existitem3.id"
                          :key="'c' + i"
                          ref="row"
                        >
                          <div class="ml-1">
                            {{ i + 1 + '.' }}
                          </div>
                          <!-- Senarai Penglibatan -->
                          <b-col
                            class="saj-title"
                            md="4"
                          >
                            <b-form-group
                              label-size="lg"
                              :label="$t('Contribution Activities')"
                              label-for="senarai-penglibatan"
                            >
                              <b-form-input
                                v-model="existitem3.involvement"
                                class="saj-text m-1"
                                type="text"
                                :disabled="disableInput9"
                                placeholder=""
                              />
                            </b-form-group>
                          </b-col>

                          <!-- Tarikh Penglibatan -->
                          <b-col
                            class="saj-title"
                            md="2"
                          >
                            <b-form-group
                              label-size="lg"
                              :label="$t('Date')"
                              label-for="tarikh penglibatan"
                            >
                              <flat-pickr
                                v-model="existitem3.date"
                                class="form-control bg-white saj-text m-1"
                                :config="{ dateFormat: 'Y-m-d' , altInput: true, altFormat: 'd-m-Y'}"
                                :disabled="disableInput10"
                              />
                            </b-form-group>
                          </b-col>

                          <!-- Jawatan -->
                          <b-col
                            class="saj-title"
                            md="5"
                          >
                            <b-form-group
                              label-size="lg"
                              :label="$t('Position / Level of Participants')"
                              label-for="jawatan"
                            >
                              <b-form-select
                                v-model="existitem3.position"
                                class="saj-text m-1"
                                :options="jawatanoptions2"
                                :disabled="disableInput11"
                              />
                            </b-form-group>
                          </b-col>
                          <!-- display attachment -->
                          <b-row
                            class="
                                saj-text
                                d-flex
                                justify-content-start
                                align-items-center
                                m-0
                                ml-3
                              "
                            style="
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 600;
                                line-height: 24px;
                                letter-spacing: 0.1em;
                                color: #000000;
                                width: 100%;
                              "
                          >
                            <div
                              v-if="existitem3.file === null"
                              class="saj-text row align-items-center"
                              style=""
                            >
                              <feather-icon
                                class="mr-1"
                                icon="PaperclipIcon"
                                size="16"
                              />
                              {{ $t("No File") }}
                            </div>
                            <div
                              v-else
                              class="saj-text row align-items-center"
                              style=""
                            >
                              <feather-icon
                                class="mr-1"
                                icon="PaperclipIcon"
                                size="16"
                              />
                              <a
                                :href="existitem3.file.url"
                                target="_blank"
                              >
                                {{ $t(existitem3.file.name) }}
                              </a>
                            </div>
                          </b-row>
                          <b-row
                            class="
                                self-score
                                d-flex
                                justify-content-end
                                align-items-center
                                m-0
                                mr-3
                              "
                            style="
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 600;
                                line-height: 24px;
                                letter-spacing: 0.1em;
                                color: #000000;
                                width: 100%;
                              "
                          >
                            {{ $t("Self Score") }}:
                            {{ getUserSelfScore2(existitem3.position) }}
                            /6
                          </b-row>
                          <b-col cols="12">
                            <hr>
                          </b-col>
                        </b-row>
                      </b-form>
                    </div>
                    <div
                      v-else
                      class="saj-text d-flex align-items-center justify-content-center"
                      style="font-style: italic;  text-align: center; height: 150px; "
                    >
                      {{ $t('No Internal Contributions Record') }}

                    </div>
                  </b-card>
                </b-col>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  // BContainer,
  // BImg,
  BButton,
  BTabs,
  BTab,
  BFormInput,
  BFormGroup,
  BFormSelect,
  // BFormDatepicker,
  // BFormFile,
  BForm,
  BFormRating,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
import flatPickr from "vue-flatpickr-component"
import axios from "axios"
import SAJToast from "@/component/saj-toastification.vue"
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    // BContainer,
    // BImg,
    BButton,
    BTabs,
    BTab,
    BFormInput,
    BFormGroup,
    BFormSelect,
    // // vSelect,
    // BFormDatepicker,
    flatPickr,
    BForm,
    // BFormFile,
    BFormRating,
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
    ...mapGetters(['roles', 'userDetails']),
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    division: {
      type: null,
      default: null,
    },
    section: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      isStatus: false,
      showExternal: false,
      showInternal: false,
      isHasContribution: false,
      isHasTraining: false,
      external_score: null,
      internal_score: null,
      training_development_id: "",
      training_development: [],
      id: this.$route.params.id,
      tambah: "",
      isEdit: false,
      disableInput1: true,
      disableInput2: true,
      disableInput3: true,
      disableInput4: true,
      disableRating1: true,
      disableInput5: true,
      disableInput6: true,
      disableInput7: true,
      disableInput8: true,
      disableRating2: true,
      disableInput9: true,
      disableInput10: true,
      disableInput11: true,
      disableInput12: true,
      disableRating3: true,
      disableTambah: true,
      total_hour: 0,
      value: "",
      disableInput: true,
      total_score1: null,
      total_score2: null,
      submit_at: null,

      existitems: [],
      existitems2: [],
      existitems3: [],
      items: [
        {
          program_name: "",
          start_date: "",
          end_date: "",
          hour: 0,
          self_score: "",
          value1: "",
        },
      ],
      items2: [
        {
          date: "",
          position: null,
          self_score: "",
          value2: "",
          involvement: "",
          type: 1,
          lampiranLuaran: [],
        },
      ],
      items3: [
        {
          date2: "",
          position2: null,
          self_score2: "",
          value3: "",
          involvement2: "",
          type: 0,
          lampiranDalaman2: [],
        },
      ],
      jawatanoptions1: [
        { value: 1, text: "Normal Member", id: 1 },
        { value: 2, text: "Committee Member", id: 2 },
        { value: 3, text: "Exco", id: 3 },
        { value: 4, text: "Treasurer/District", id: 4 },
        { value: 5, text: "Secretary/State", id: 5 },
        { value: 6, text: "Chairman/President/National", id: 6 },
      ],
      jawatanoptions2: [
        { value: 1, text: "Normal Member", id: 1 },
        { value: 2, text: "Committee Member", id: 2 },
        { value: 3, text: "Exco", id: 3 },
        { value: 4, text: "Treasurer/District", id: 4 },
        { value: 5, text: "Secretary/State", id: 5 },
        { value: 6, text: "Chairman/President/National", id: 6 },
      ],
      total: this.calculateTotal,
      performance_id: this.$route.params.performance_id,
      isSupervisor: null,
      isAppraiserSv: null,
      rating: [],
      approved_at: '',
      approved_sv_at: null,
      agree_at: null,
      approved_hod_at: null,
      approved_hr_at: null,
      isApproveSHR: false,
      isApproveHOD: false,
      isApproveHR: false,
      isApproveSV: false,
      subsidiary_id: null,
      grade_set_id: null,
      training_weightage: null,
      self_learn_weightage: null,
      internal_weightage: null,
      total_weight_tm: null,
    }
  },
  mounted() {
    this.getScoreRating()
    this.getData()
    this.getData1()
    // this.getContribution()
    this.currentUser()
    this.checkSupervisorStatus()
    // console.log(this.section)
    // console.log('user performance id', this.performance_id)
    // console.log(this.division)
  },
  methods: {
    getScoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll?option=part3`).then(response => {
        this.rating = response.data.data
      })
    },
    getUserSelfScore(data) {
      let score = "--"
      this.jawatanoptions1.forEach(item => {
        if (data === item.value) {
          score = item.id
        }
      })
      return score
    },
    getUserSelfScore2(data) {
      let score = "--"
      this.jawatanoptions2.forEach(item => {
        if (data === item.value) {
          score = item.id
        }
      })
      return score
    },
    checkSupervisorStatus(){
      this.$axios.get(`${this.$baseUrl}/supervisor/checkSupervisory?performance_id=${this.performance_id}&user_id=${this.userDetails.user_id}`).then(response => {
        this.isSupervisor = response.data.data.status.is_supervisor
        this.isAppraiserSv = response.data.data.status.is_appraiser_sv
        // console.log("ini sv ke", this.isSupervisor)
        // console.log("ini appraiser sv ke", this.isAppraiserSv)
      })
    },
    // Form Repeater 1
    repeateAgain() {
      this.items.push({
        // id: (this.nextTodoId += this.nextTodoId),
        program_name: "",
        start_date: "",
        end_date: "",
        hour: "",
        self_score: "",
        skor: "",
        value1: "",
        file: {},
      })
    },
    toggleInput1() {
      this.isEdit = !this.isEdit
      this.disableInput1 = !this.disableInput1
      this.disableInput2 = !this.disableInput2
      this.disableInput3 = !this.disableInput3
      this.disableInput4 = !this.disableInput4
      this.disableRating1 = !this.disableRating1
    },
    toggleInput2() {
      this.disableInput5 = !this.disableInput5
      this.disableInput6 = !this.disableInput6
      this.disableInput7 = !this.disableInput7
      this.disableInput8 = !this.disableInput8
      this.disableRating2 = !this.disableRating2
      this.disableInput9 = !this.disableInput9
      this.disableInput10 = !this.disableInput10
      this.disableInput11 = !this.disableInput11
      this.disableInput12 = !this.disableInput12
      this.disableRating3 = !this.disableRating3
    },
    toggleInput3() {
      this.disableTambah = !this.disableTambah
    },
    getData() {
      const data = new FormData()
      axios
        .get(
          `${this.$baseUrl}/myperformances/training_development?user_id=${this.id}`,
          data,
        )
        .then(response => {
          this.isHasTraining = response.data.data.hasTraining === 1
          if (this.isHasTraining){
            // console.log(response)
            this.submit_at = response.data.data.submit_at
            if (response.data.data.training_development.length > 0) {
              for (
                let i = 0;
                i < response.data.data.training_development.length;
                i += 1
              ) {
                this.training_development_id = response.data.data.training_development[i].id
                // console.warn(response.data.data.training_development[i].start_date)
                this.existitems.push({
                  id: response.data.data.training_development[i].id,
                  program_name: response.data.data.training_development[i].program_name,
                  start_date: response.data.data.training_development[i].start_date,
                  end_date: response.data.data.training_development[i].end_date,
                  hour: response.data.data.training_development[i].hour,
                  summary_comment: response.data.data.training_development[i].summary_comment,
                  skor: response.data.data.training_development[i].self_score,
                  value1: response.data.data.training_development[i].manager_score,
                  file: response.data.data.training_development[i].file,
                  fileToUpload: null,
                  self_learn: response.data.data.training_development[i].self_learn,
                // name: response.data.data.training_development[i].file.name,
                // url: response.data.data.training_development[i].file.url,
                })
              }
            }
          }
          this.total_score1 = response.data.data.total_manager_score
          this.total_hour = response.data.data.total_hour
          this.training_development = response.data.data.training_development

          this.approved_at = response.data.data.approved_at // SHR approved
          this.approved_sv_at = response.data.data.approved_sv_at // SV approved
          this.agree_at = response.data.data.agree_at // Employee agree
          this.approved_hod_at = response.data.data.approved_hod_at // HOD approved
          this.approved_hr_at = response.data.data.approved_hr_at // HR approved

          switch (this.roles.selectedRole){
            case 1:
              // Admin
              // console.log('admin')
              if (this.agree_at !== null && this.approved_at === null){
                // console.log('masuk admin')
                this.isApproveSHR = true
              }
              break
            case 3:
              // Executive
              // console.log('Executive')
              if (this.agree_at === null){
                // console.log('masuk exec')
                this.isApproveSV = true
              }
              break
            case 4:
              // Top Management
              // console.log('Top Management')
              if (this.agree_at === null){
                // console.log('masuk tm')
                this.isApproveSV = true
              }
              break
            case 5:
              // Head of Department
              // console.log('Head of Department')
              if (this.agree_at !== null && this.approved_hod_at === null){
                // console.log('masuk hod')
                this.isApproveHOD = true
              }
              break
            case 6:
              // Human Resource
              // console.log('Human Resource')
              if (this.agree_at !== null && this.approved_hr_at === null){
                // console.log('masuk hr')
                this.isApproveHR = true
              }
              break
            default:
              break
          }
        })
    },
    getData1() {
      const data = new FormData()
      axios
        .get(
          `${this.$baseUrl}/myperformances/contribution?user_id=${this.id}`,
          data,
        )
        .then(response => {
          // console.log("length", response.data.data.contribution);
          this.isStatus = response.data.data.internal_industry_status === 'enable'
          // console.log(response)
          this.isHasContribution = response.data.data.hasContribution === 1
          if (this.isHasContribution){
            for (let i = 0; i < response.data.data.contribution.length; i += 1) {
            // console.log("satu");
              if (response.data.data.contribution[i].type === 1) {
                this.existitems2.push({
                  id: response.data.data.contribution[i].id,
                  involvement: response.data.data.contribution[i].involvement,
                  date: response.data.data.contribution[i].date,
                  position: response.data.data.contribution[i].position,
                  skor1: response.data.data.contribution[i].self_score,
                  value2: response.data.data.contribution[i].manager_score,
                  file: response.data.data.contribution[i].file,
                  fileToUpload: null,

                })
              } else {
                this.existitems3.push({
                  id: response.data.data.contribution[i].id,
                  involvement: response.data.data.contribution[i].involvement,
                  date: response.data.data.contribution[i].date,
                  position: response.data.data.contribution[i].position,
                  skor2: response.data.data.contribution[i].self_score,
                  value3: response.data.data.contribution[i].manager_score,
                  file: response.data.data.contribution[i].file,
                  fileToUpload: null,
                })
              }
            }
          }
          this.total_score2 = response.data.data.total_manager_score
          this.external_score = response.data.data.external_score
          this.internal_score = response.data.data.internal_score
        })
    },
    // getContribution() {
    //   const data = new FormData()
    //   axios
    //     .get(
    //       `${this.$baseUrl}/myperformances/contribution?user_id=${this.id}`,
    //       data,
    //     )
    //     .then(response => {
    //       // console.log("length", response.data.data.contribution);
    //       // console.log(response)
    //       this.isHasContribution = response.data.data.hasContribution === 1
    //       if (this.isHasContribution){
    //         for (let i = 0; i < response.data.data.contribution.length; i += 1) {
    //         // console.log("satu");

    //           this.existitems3.push({
    //             id: response.data.data.contribution[i].id,
    //             involvement: response.data.data.contribution[i].involvement,
    //             date: response.data.data.contribution[i].date,
    //             position: response.data.data.contribution[i].position,
    //             skor2: response.data.data.contribution[i].self_score,
    //             value3: response.data.data.contribution[i].manager_score,
    //             file: response.data.data.contribution[i].file,
    //             fileToUpload: null,
    //           })
    //         }
    //       }
    //       this.total_score2 = response.data.data.total_manager_score
    //       this.external_score = response.data.data.external_score
    //       this.internal_score = response.data.data.internal_score
    //     })
    // },
    currentUser(){
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/supervisor/getEmployee?user_id=${this.id}`, data).then(response => {
        // console.log('res', response.data.data)
        this.subsidiary_id = response.data.data.employee.business_unit
        this.grade_set_id = response.data.data.grade_set_id
        // console.log(this.subsidiary_id, this.grade_set_id)
        this.weightage()
      })
    },
    weightage(){
      const params = new URLSearchParams()
      params.append('subsidiary_id', this.subsidiary_id)
      params.append('role_id', this.grade_set_id)

      this.$axios.get(`${this.$baseUrl}/weightage/get_role_percentage`, { params }).then(response => {
        // console.log(response)
        this.training_weightage = response.data.data.training
        this.self_learn_weightage = response.data.data.self_learning
        this.internal_weightage = response.data.data.internal_industry_involement
        this.total_weight_tm = this.training_weightage + this.self_learn_weightage
      })
    },
    ManagerScoreTraining() {
      const data = new FormData()
      for (let i = 0; i < this.items.length; i += 1) {
        data.append("user_id", this.id)
        data.append(`manager_score[${i}]`, this.items[i].value1)
      }

      for (let i = 0; i < this.existitems.length; i += 1) {
        const idd = this.existitems[i].id
        data.append("user_id", this.id)
        data.append(`training_development_id[${i}]`, idd)
        data.append(`manager_score[${idd}]`, this.existitems[i].value1)
      }

      data.append(`current_role`, this.roles.selectedRole)
      axios
        .post(
          `${this.$baseUrl}/manager/training_development_manager_score_store`,
          data,
        )
        .then(() => {
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Manager score successfully saved')}.`,
                icon: "CheckCircleIcon",
                variant: "success",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        })
    },
    ManagerScoreContribution() {
      const data = new FormData()
      let i = 0
      for (i; i < this.items2.length; i += 1) {
        // const date = this.items2[i].date.split("-").reverse().join("-")
        data.append("user_id", this.id)
        data.append(`manager_score[${i}]`, this.items2[i].value2)
        data.append(`type[${i}]`, 1)
      }
      let i2 = 0
      for (i2; i2 < this.existitems2.length; i2 += 1) {
        // const date = this.items2[i].date.split("-").reverse().join("-")
        const idd = this.existitems2[i2].id
        // console.log(idd)

        data.append(`contribution_id[${i2}]`, idd)
        data.append(`manager_score[${idd}]`, this.existitems2[i2].value2)
        data.append(`type[${idd}]`, 1)
      }
      let j = i
      let k = 0
      for (j; j < this.items3.length + i; j += 1) {
        data.append(`manager_score[${j}]`, this.items3[k].value3)
        data.append(`type[${j}]`, 0)
        k += 1
      }
      let j2 = i2
      k = 0
      for (j2; j2 < this.existitems3.length + i2; j2 += 1) {
        // const date2 = this.items3[k].date2.split("-").reverse().join("-")
        const iddd = this.existitems3[k].id
        data.append(`contribution_id[${j}]`, iddd)
        data.append(`manager_score[${iddd}]`, this.existitems3[k].value3)
        data.append(`type[${iddd}]`, 0)
        k += 1
      }
      data.append(`current_role`, this.roles.selectedRole)
      axios
        .post(`${this.$baseUrl}/manager/contribution_manager_score_store`, data)
        .then(() => {
          // console.log(response)
          // this.getData1();
        })
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.disable-flat-pickr {
  background-color: gray;
}
a {
  color: black;
}
a:hover {
  color: red;
}
</style>
