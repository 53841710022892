<template>
  <div class="mt-3">
    <section id="card-navigation">
      <b-row>
        <!-- pill tabs inside card -->

        <b-col md="12">
          <b-card
            class=""
            style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
            "
          >
            <b-card-header>
              <span
                class="saj-title m-0"
                style="
                  font-family: Poppins;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 30px;
                  letter-spacing: 0.4px;
                  color: #000000;
                "
              >
                {{ $t("Part 2") }} : {{ $t("Competency Assessment") }} ( {{ $t("divided into two") }} (2) {{ $t("competencies") }} ).
              </span>
            </b-card-header>

            <hr>
            <b-tabs
              nav-class="saj-title m-0"
              style="
                line-height: 30px;
                letter-spacing: 0.1em;
              "
            >
              <!-- kompetensi teras -->
              <b-tab
                :title="$t('2a. Core Competencies')"
                class="saj-title"
                active
              >
                <div class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end">
                  {{ total_score1 }}/{{ core_weightage }} %
                </div>
                <b-col
                  style="
                      display: flex;
                      justify-content: flex-end;
                      margin-bottom: 10px;
                    "
                >
                  <div v-if="submit_at_core !== null && (roles.selectedRole === 1 || ((roles.selectedRole === 3 || (roles.selectedRole === 4 && (!division || !section) )) && isSupervisor === 1) || roles.selectedRole === 5 || roles.selectedRole === 6)">
                    <!-- {{ isApproveSV }} {{ roles.selectedRole }} -->
                    <b-button
                      variant="primary"
                      class="saj-button"
                      style="margin-right: 10px"
                      :style="isApproveSV_core || isApproveHOD_core || isApproveHR_core || isApproveSHR_core ? '' : 'display: none;'"
                      @click="toggleInput()"
                    >
                      {{ $t("Edit") }}
                    </b-button>
                    <b-button
                      class="saj-button"
                      style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                      :style="isApproveSV_core || isApproveHOD_core || isApproveHR_core || isApproveSHR_core ? '' : 'display: none;'"
                      @click="ManagerScoreCore(), toggleInput()"
                    >
                      {{ $t("Save") }}
                    </b-button>
                  </div>
                </b-col>
                <div
                  class="saj-text m-0 ml-1 mt-2 mb-2 p-0"
                  style="
                      letter-spacing: 0.4px;
                      color: #000000;
                    "
                >
                  {{ $t("Please rate all three competencies and the key items for each of those competencies") }}.
                  {{ $t("This competency is the foundation") }}/
                  {{ $t("value of the company that must be possessed by all members in all positions") }}.
                </div>
                <!-- Teras 1 -->
                <b-col>
                  <b-card
                    class="mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <b-row class="text-center">
                      <b-col
                        cols="12"
                        md="8"
                        class="saj-title d-flex justify-content-start mt-2"
                        style="
                            display: flex;
                            align-items: flex-start;
                            font-weight: 400;
                            line-height: 30px;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                      >
                        {{ $t("CORE") }} 1:
                        <span
                          class="saj-title"
                          style="
                              font-weight: 700;
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 115px;
                              margin-bottom: 10px;
                            "
                        >{{ isEnglish ? corename1.name_bi : corename1.name }}</span>
                      </b-col>
                      <!-- <b-col
                          class="d-flex justify-content-end"
                          style="
                          align-items-center;
                          "
                        >
                          <button
                            class="btn-danger justify-content-end mb-1 mt-1"
                            style="
                              width: 75px;
                              height: 39px;
                              border-radius: 4px;
                            "
                            @click="showModalAdd(core1)"
                          >
                            Add
                          </button>
                        </b-col> -->
                    </b-row>
                    <!-- card 1 inside -->
                    <b-card
                      class="m-0"
                      style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                    >
                      <b-row style="height: 100%">
                        <b-col
                          cols="2"
                          class="saj-text"
                          style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                        >{{ isEnglish ? name0.name_bi : name0.name }}</b-col>
                        <b-col
                          cols="10"
                          class="saj-text pl-2"
                          style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                        >{{ isEnglish ? description0.description_bi : description0.description }}</b-col>
                      </b-row>
                    </b-card>
                    <div
                      class="self-score d-flex justify-content-end"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Self Score") }}: {{ skor1 }}/6
                    </div>
                    <b-row
                      class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                        "
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Manager Score") }}:

                      <b-form-rating
                        v-model="value1"
                        show-clear
                        style="background: none !important"
                        no-border
                        show-value
                        inline
                        color="#DFB300"
                        stars="6"
                        size="lg"
                        :disabled="disableRating"
                      />
                      <span style="margin-top: 5px; margin-right: 15px">
                        <h5 v-if="value1 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="value1 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="value1 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="value1 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="value1 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="value1 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-row>

                    <!-- card 2 inside -->
                    <b-card
                      class="m-0"
                      style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                    >
                      <b-row style="height: 100%">
                        <b-col
                          cols="2"
                          class="saj-text"
                          style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                        >{{ isEnglish ? name1.name_bi : name1.name }}</b-col>
                        <b-col
                          cols="10"
                          class="saj-text"
                          style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                        >{{ isEnglish ? description1.description_bi : description1.description }}</b-col>
                      </b-row>
                    </b-card>
                    <div
                      class="self-score d-flex justify-content-end"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Self Score") }}: {{ skor2 }}/6
                    </div>
                    <b-row
                      class="self-score d-flex justify-content-end align-items-center"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Manager Score") }}:

                      <b-form-rating
                        v-model="value2"
                        show-clear
                        style="background: none !important"
                        no-border
                        show-value
                        inline
                        color="#DFB300"
                        stars="6"
                        size="lg"
                        :disabled="disableRating"
                      />
                      <span style="margin-top: 5px; margin-right: 15px">
                        <h5 v-if="value2 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="value2 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="value2 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="value2 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="value2 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="value2 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- Teras 2 -->
                <b-col>
                  <b-card
                    class="mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="8"
                        class="saj-title d-flex justify-content-start mt-2"
                        style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                      >{{ $t("CORE") }} 2:
                        <span
                          class="saj-title"
                          style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                        >{{ isEnglish ? corename2.name_bi : corename2.name }}</span>
                      </b-col>
                    </b-row>
                    <!-- card 1 inside -->
                    <b-card
                      class="m-0"
                      style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                    >
                      <b-row style="height: 100%">
                        <b-col
                          cols="2"
                          class="saj-text"
                          style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                        >{{ isEnglish ? name2.name_bi : name2.name }}</b-col>
                        <b-col
                          cols="10"
                          class="saj-text"
                          style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                        >{{ isEnglish ? description2.description_bi : description2.description }}</b-col>
                      </b-row>
                    </b-card>
                    <div
                      class="self-score d-flex justify-content-end"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Self Score") }}: {{ skor3 }}/6
                    </div>
                    <b-row
                      class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                        "
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Manager Score") }}:

                      <b-form-rating
                        v-model="value3"
                        show-clear
                        style="background: none !important"
                        no-border
                        show-value
                        inline
                        color="#DFB300"
                        stars="6"
                        size="lg"
                        :disabled="disableRating"
                      />
                      <span style="margin-top: 5px; margin-right: 15px">
                        <h5 v-if="value3 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="value3 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="value3 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="value3 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="value3 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="value3 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-row>

                    <!-- card 2 inside -->
                    <b-card
                      class="m-0"
                      style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                    >
                      <b-row style="height: 100%">
                        <b-col
                          cols="2"
                          class="saj-text"
                          style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              <!-- background:lightgreen; -->
                            "
                        >{{ isEnglish ? name3.name_bi : name3.name }}</b-col>
                        <b-col
                          cols="10"
                          class="saj-text"
                          style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                              <!-- background:lightblue; -->
                            "
                        >{{ isEnglish ? description3.description_bi : description3.description }}</b-col>
                      </b-row>
                    </b-card>
                    <div
                      class="self-score d-flex justify-content-end"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Self Score") }}: {{ skor4 }}/6
                    </div>
                    <b-row
                      class="self-score d-flex justify-content-end align-items-center"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Manager Score") }}:

                      <b-form-rating
                        v-model="value4"
                        show-clear
                        style="background: none !important"
                        no-border
                        show-value
                        inline
                        color="#DFB300"
                        stars="6"
                        size="lg"
                        :disabled="disableRating"
                      />
                      <span style="margin-top: 5px; margin-right: 15px">
                        <h5 v-if="value4 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="value4 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="value4 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="value4 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="value4 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="value4 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- Teras 3 -->
                <b-col>
                  <b-card
                    class="mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="8"
                        class="saj-title d-flex justify-content-start mt-2"
                        style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                      >{{ $t("CORE") }} 3:
                        <span
                          class="saj-title"
                          style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                        >{{ isEnglish ? corename3.name_bi : corename3.name }}</span>
                      </b-col>
                    </b-row>
                    <!-- card 1 inside -->
                    <b-card
                      class="m-0"
                      style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                    >
                      <b-row style="height: 100%">
                        <b-col
                          cols="2"
                          class="saj-text"
                          style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                        >{{ isEnglish ? name4.name_bi : name4.name }}</b-col>
                        <b-col
                          cols="10"
                          class="saj-text"
                          style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                        >{{ isEnglish ? description4.description_bi : description4.description }}</b-col>
                      </b-row>
                    </b-card>
                    <div
                      class="self-score d-flex justify-content-end"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Self Score") }}: {{ skor5 }}/6
                    </div>
                    <b-row
                      class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                        "
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Manager Score") }}:

                      <b-form-rating
                        v-model="value5"
                        show-clear
                        style="background: none !important"
                        no-border
                        show-value
                        inline
                        color="#DFB300"
                        stars="6"
                        size="lg"
                        :disabled="disableRating"
                      />
                      <span style="margin-top: 5px; margin-right: 15px">
                        <h5 v-if="value5 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="value5 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="value5 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="value5 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="value5 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="value5 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-row>

                    <!-- card 2 inside -->
                    <b-card
                      class="m-0"
                      style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                    >
                      <b-row style="height: 100%">
                        <b-col
                          cols="2"
                          class="saj-text"
                          style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                        >{{ isEnglish ? name5.name_bi : name5.name }}</b-col>
                        <b-col
                          cols="10"
                          class="saj-text"
                          style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                        >{{ isEnglish ? description5.description_bi : description5.description }}</b-col>
                      </b-row>
                    </b-card>
                    <div
                      class="self-score d-flex justify-content-end"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Self Score") }}: {{ skor6 }}/6
                    </div>
                    <b-row
                      class="self-score d-flex justify-content-end align-items-center"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Manager Score") }}:

                      <b-form-rating
                        v-model="value6"
                        show-clear
                        style="background: none !important"
                        no-border
                        show-value
                        inline
                        color="#DFB300"
                        stars="6"
                        size="lg"
                        :disabled="disableRating"
                      />
                      <span style="margin-top: 5px; margin-right: 15px">
                        <h5 v-if="value6 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="value6 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="value6 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="value6 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="value6 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="value6 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-row>
                  </b-card>
                </b-col>
              </b-tab>
              <!-- kompetensi tingkahlaku -->
              <b-tab :title="$t('2b. Behaviourial Competencies')">
                <div class="saj-title mt-1 mr-1 mb-3 d-flex justify-content-end">
                  {{ total_score2 }}/{{ behaviour_weightage }} %
                </div>
                <b-col

                  style="
                      display: flex;
                      justify-content: flex-end;
                      margin-bottom: 10px;
                    "
                  @click="toggleInput2()"
                >
                  <div v-if="submit_at_behavior !== null && (roles.selectedRole === 1 || ((roles.selectedRole === 3 || (roles.selectedRole === 4 && (!division || !section) )) && isSupervisor === 1) || roles.selectedRole === 5 || roles.selectedRole === 6)">
                    <b-button
                      variant="primary"
                      class="saj-button"
                      style="margin-right: 10px"
                      :style="isApproveSV_bhv || isApproveHOD_bhv || isApproveHR_bhv || isApproveSHR_bhv ? '' : 'display: none;'"
                    >
                      {{ $t("Edit") }}
                    </b-button>
                    <b-button
                      class="saj-button"
                      style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                      :style="isApproveSV_bhv || isApproveHOD_bhv || isApproveHR_bhv || isApproveSHR_bhv ? '' : 'display: none;'"
                      @click="ManagerScoreBehaviour()"
                    >
                      {{ $t("Save") }}
                    </b-button>
                  </div>
                </b-col>
                <div
                  class="saj-text m-0 ml-1 mt-2 mb-2 p-0"
                  style="
                      font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 400;
                      line-height: 30px;
                      letter-spacing: 0.4px;
                      color: #000000;
                    "
                >
                  {{ $t("Please value this Behavioral Competency as stated in the Job Competency Matrix") }}.
                  {{ $t("It is a trait") }} /
                  {{ $t("characteristic needed to produce better work performance") }}.
                </div>
                <b-col
                  class="d-flex justify-content-end"
                  style="
                          align-items-center;

                          "
                >
                  <b-modal
                    v-model="modalShow"
                    class="modal-behaviour"
                    size="lg"
                    scrollable
                    :hide-footer="true"
                  >
                    <div
                      class="
                          saj-title
                          d-flex
                          justify-content-start
                          font-weight-bolder
                          mb-1
                          mt-1
                        "
                      style="
                          font-family: Poppins;
                          font-weight: 400;
                          font-style: normal;
                          color: black;
                        "
                    >
                      {{ $t("Behavior Competencies") }}
                    </div>
                    <b-row>
                      <div class="d-flex align-items-start ml-1">
                        <h3
                          class="saj-text"
                          style="
                              font-family: Poppins;
                              font-weight: 400;
                              font-style: normal;
                              color: #464e5f;
                            "
                        >
                          {{ $t("Select the following five") }} (5) {{ $t("items") }}:
                          <!-- {{ selected }} -->
                        </h3>
                      </div>
                    </b-row>

                    <b-row>
                      <b-card-body class="">
                        <div
                          v-for="(item, index) in behaviourData"
                          :key="index"
                          class="
                              employee-task
                              d-flex
                              justify-content-between
                              align-items-start
                            "
                        >
                          <b-row>
                            <b-col
                              cols="1"
                              class="justify-content-start"
                              style=""
                            >
                              <div class="d-flex align-items-center">
                                <b-form-checkbox
                                  v-model="selected"
                                  name="checkbox-1"
                                  label="id of product"
                                  type="checkbox"
                                  :value="item.performance_behaviour.id"
                                />
                              </div>
                            </b-col>
                            <b-col
                              cols="10"
                              class="justify-content-start"
                              style=""
                            >
                              <div class="d-flex align-items-start">
                                <h3
                                  class="saj-text font-weight-bolder"
                                  style="
                                      font-family: Poppins;
                                      font-weight: 400;
                                      font-style: normal;
                                      color: #464e5f;
                                    "
                                >
                                  {{ $t(item.performance_behaviour.name) }}

                                  <!-- <div
                                      class="bg-warning"
                                      v-for="(
                                        sub, index
                                      ) in item.performance_subbehaviour"
                                      :key="index"
                                    >
                                      {{ sub.name }}
                                    </div> -->
                                </h3>
                              </div>
                            </b-col>
                            <b-col
                              cols="10"
                              class="justify-content-start ml-3"
                              style=""
                            >
                              <div
                                v-for="(
                                  // eslint-disable-next-line vue/no-template-shadow
                                  sub, index
                                ) in item.performance_subbehaviour"
                                :key="index"
                                class=""
                              >
                                <h3
                                  class="font-weight-bolder"
                                  style="
                                      font-family: Poppins;
                                      font-style: normal;

                                      color: black;
                                      align-items: start;
                                    "
                                >
                                  <ul class="saj-text">
                                    <li
                                      style="
                                          font-family: Poppins;
                                          font-weight: 400;
                                          font-style: normal;
                                          color: black;
                                          align-items: start;
                                        "
                                    >
                                      {{ $t(sub.name) }}
                                    </li>
                                  </ul>
                                </h3>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-card-body>
                    </b-row>
                    <!-- button save and cancel -->
                    <div
                      class="d-flex justify-content-center"
                      style=""
                    >
                      <button
                        class="saj-title btn btn-close m-1"
                        aria-label="Close modal"
                        style="
                            font-family: Poppins;
                            font-weight: 400;
                            font-style: normal;
                            color: white;
                            background: #ff0000;
                          "
                        @click="close()"
                      >
                        {{ $t("Back") }}
                      </button>
                      <button
                        class="saj-title btn m-1"
                        aria-label="submit modal"
                        style="
                            font-family: Poppins;
                            font-weight: 400;
                            font-style: normal;
                            color: white;
                            background: #0b103c !important;
                            border-color: #0b103c !important;
                          "
                        @click="AssignBehavior()"
                      >
                        {{ $t("Save") }}
                      </button>
                    </div>
                    <!-- save and cancel -->
                  </b-modal>
                  <!-- End Modal -->
                </b-col>
                <!-- Tingkahlaku 1 -->
                <b-col>
                  <b-card
                    class="mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        class="saj-title"
                        style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                      >{{ $t("BEHAVIOURIAL") }} 1:
                        <span
                          class="saj-title"
                          style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                        >{{ isEnglish ? name6.name_bi : name6.name }}</span></b-col>
                      <b-col
                        class="d-flex justify-content-end"
                        style="
                          align-items-center;
                          <!-- background-color: red; -->
                          "
                      >
                        <!-- <button
                            class="btn-danger justify-content-end mb-1 mt-1"
                            style="
                              width: 75px;
                              height: 39px;
                              border-radius: 4px;
                            "
                            @click="showModalAdd2()"
                          >
                            Add
                          </button> -->
                      </b-col>
                    </b-row>
                    <!-- card 1 inside -->
                    <b-card
                      class="m-0"
                      style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                    >
                      <b-row style="height: 100%">
                        <b-col
                          v-for="(description, index) in description6"
                          :key="index"
                          cols="12"
                          class="saj-text"
                          style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                        >
                          <ul><li> {{ isEnglish ? description.name_bi : description.name }}</li></ul>
                        </b-col>
                      </b-row>
                    </b-card>
                    <div
                      class="self-score d-flex justify-content-end"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Self Score") }}: {{ skor7 }}/6
                    </div>
                    <b-row
                      class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          m-0
                        "
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Manager Score") }}:

                      <b-form-rating
                        v-model="value7"
                        show-clear
                        style="background: none !important"
                        no-border
                        show-value
                        inline
                        color="#DFB300"
                        stars="6"
                        size="lg"
                        :disabled="disableRating2"
                      />
                      <span style="margin-top: 5px; margin-right: 15px">
                        <h5 v-if="value7 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="value7 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="value7 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="value7 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="value7 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="value7 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- Tingkahlaku 2 -->
                <b-col>
                  <b-card
                    class="mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        class="saj-title"
                        style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                      >{{ $t("BEHAVIOURIAL") }} 2:
                        <span
                          class="saj-title"
                          style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                        >{{ isEnglish ? name7.name_bi : name7.name }}</span></b-col>
                      <b-col
                        class="d-flex justify-content-end"
                        style="
                          align-items-center;
                          <!-- background-color: red; -->
                          "
                      />
                    </b-row>
                    <!-- card 1 inside -->
                    <b-card
                      class="m-0"
                      style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                    >
                      <b-row style="height: 100%">
                        <b-col
                          v-for="(description, index) in description7"
                          :key="index"
                          cols="12"
                          class="saj-text"
                          style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                        >
                          <ul><li> {{ isEnglish ? description.name_bi : description.name }}</li></ul>
                        </b-col>
                      </b-row>
                    </b-card>
                    <div
                      class="self-score d-flex justify-content-end"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Self Score") }}: {{ skor8 }}/6
                    </div>
                    <b-row
                      class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          m-0
                        "
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Manager Score") }}:

                      <b-form-rating
                        v-model="value8"
                        show-clear
                        style="background: none !important"
                        no-border
                        show-value
                        inline
                        color="#DFB300"
                        stars="6"
                        size="lg"
                        :disabled="disableRating2"
                      />
                      <span style="margin-top: 5px; margin-right: 15px">
                        <h5 v-if="value8 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="value8 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="value8 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="value8 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="value8 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="value8 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- Tingkahlaku 3 -->
                <b-col>
                  <b-card
                    class="mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        class="saj-title"
                        style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                      >{{ $t("BEHAVIOURIAL") }} 3:
                        <span
                          class="saj-title"
                          style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                        >{{ isEnglish ? name8.name_bi : name8.name }}</span></b-col>
                      <b-col
                        class="d-flex justify-content-end"
                        style="
                          align-items-center;
                          <!-- background-color: red; -->
                          "
                      />
                    </b-row>
                    <!-- card 1 inside -->
                    <b-card
                      class="m-0"
                      style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                    >
                      <b-row style="height: 100%">
                        <b-col
                          v-for="(description, index) in description8"
                          :key="index"
                          cols="12"
                          class="saj-text"
                          style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                        >
                          <ul><li> {{ isEnglish ? description.name_bi : description.name }}</li></ul>
                        </b-col>
                      </b-row>
                    </b-card>
                    <div
                      class="self-score d-flex justify-content-end"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Self Score") }}: {{ skor9 }}/6
                    </div>
                    <b-row
                      class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          m-0
                        "
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Manager Score") }}:

                      <b-form-rating
                        v-model="value9"
                        show-clear
                        style="background: none !important"
                        no-border
                        show-value
                        inline
                        color="#DFB300"
                        stars="6"
                        size="lg"
                        :disabled="disableRating2"
                      />
                      <span style="margin-top: 5px; margin-right: 15px">
                        <h5 v-if="value9 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="value9 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="value9 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="value9 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="value9 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="value9 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- Tingkahlaku 4 -->
                <b-col>
                  <b-card
                    class="mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        class="saj-title"
                        style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                      >{{ $t("BEHAVIOURIAL") }} 4:
                        <span
                          class="saj-title"
                          style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                        >{{ isEnglish ? name9.name_bi : name9.name }}</span></b-col>
                      <b-col
                        class="d-flex justify-content-end"
                        style="
                          align-items-center;
                          <!-- background-color: red; -->
                          "
                      />
                    </b-row>
                    <!-- card 1 inside -->
                    <b-card
                      class="m-0"
                      style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                    >
                      <b-row style="height: 100%">
                        <b-col
                          v-for="(description, index) in description9"
                          :key="index"
                          cols="12"
                          class="saj-text"
                          style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                        >
                          <ul><li> {{ isEnglish ? description.name_bi : description.name }}</li></ul>
                        </b-col>
                      </b-row>
                    </b-card>
                    <div
                      class="self-score d-flex justify-content-end"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Self Score") }}: {{ skor10 }}/6
                    </div>
                    <b-row
                      class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          m-0
                        "
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Manager Score") }}:

                      <b-form-rating
                        v-model="value10"
                        show-clear
                        style="background: none !important"
                        no-border
                        show-value
                        inline
                        color="#DFB300"
                        stars="6"
                        size="lg"
                        :disabled="disableRating2"
                      />
                      <span style="margin-top: 5px; margin-right: 15px">
                        <h5 v-if="value10 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="value10 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="value10 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="value10 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="value10 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="value10 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- Tingkahlaku 5 -->
                <b-col>
                  <b-card
                    class="mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        class="saj-title"
                        style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                      >{{ $t("BEHAVIOURIAL") }} 5:
                        <span
                          class="saj-title"
                          style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                        >{{ isEnglish ? name10.name_bi : name10.name }}</span></b-col>
                      <b-col
                        class="d-flex justify-content-end"
                        style="
                          align-items-center;
                          <!-- background-color: red; -->
                          "
                      />
                    </b-row>
                    <!-- card 1 inside -->
                    <b-card
                      class="m-0"
                      style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                    >
                      <b-row style="height: 100%">
                        <b-col
                          v-for="(description, index) in description10"
                          :key="index"
                          cols="12"
                          class="saj-text"
                          style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                        >
                          <ul><li> {{ isEnglish ? description.name_bi : description.name }}</li></ul>
                        </b-col>
                      </b-row>
                    </b-card>
                    <div
                      class="self-score d-flex justify-content-end"
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Self Score") }}: {{ skor11 }}/6
                    </div>
                    <b-row
                      class="
                          self-score
                          d-flex
                          justify-content-end
                          align-items-center
                          m-0
                        "
                      style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          line-height: 30px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                    >
                      {{ $t("Manager Score") }}:

                      <b-form-rating
                        v-model="value11"
                        show-clear
                        style="background: none !important"
                        no-border
                        show-value
                        inline
                        color="#DFB300"
                        stars="6"
                        size="lg"
                        :disabled="disableRating2"
                      />
                      <span style="margin-top: 5px; margin-right: 15px">
                        <h5 v-if="value11 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="value11 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="value11 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="value11 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="value11 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="value11 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-row>
                  </b-card>
                </b-col>
              </b-tab>

              <!-- <b-tab title="Disable" disabled /> -->
              <modalAdd
                v-show="isModalAddVisible"
                :teras="coreName"
                :description="coreDescription"
                :subcore="subcoreList"
                @close="closeModalAdd()"
                @select-core="selectCore"
              />
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BButton,
  BTabs,
  BTab,
  BFormRating,
  BModal,
  BCardBody,
  BFormCheckbox,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
// import axios from "axios"
import modalAdd from "@/pages/exec/ModalAddC.vue"
// import modalAdd2 from "@/pages/ModalAdd2.vue"
import SAJToast from "@/component/saj-toastification.vue"
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BButton,
    BTabs,
    BTab,
    BFormRating,
    modalAdd,
    BModal,
    BCardBody,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    division: {
      type: null,
      default: null,
    },
    section: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      submit_at_core: null,
      submit_at_behavior: null,
      selected: [],
      behaviourData: [],
      competency_core_performances_id: "",
      id: this.$route.params.id,
      subcoreList: [],
      coreName: "",
      coreDescription: "",
      name0: "",
      name1: "",
      name2: "",
      name3: "",
      name4: "",
      name5: "",
      name6: "",
      name7: "",
      name8: "",
      name9: "",
      name10: "",
      core1: "",
      core2: "",
      core3: "",
      subcore1_1: "",
      subcore1_2: "",
      subcore2_1: "",
      subcore2_2: "",
      subcore3_1: "",
      subcore3_2: "",
      corename1: "",
      corename2: "",
      corename3: "",
      description0: "",
      description1: "",
      description2: "",
      description3: "",
      description4: "",
      description5: "",
      description6: [],
      description7: [],
      description8: [],
      description9: [],
      description10: [],
      /// ////////////// core //////////////////////
      skor1: "",
      skor2: "",
      skor3: "",
      skor4: "",
      skor5: "",
      skor6: "",
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      /// //////////////// behaviour //////////////////////
      skor7: "",
      skor8: "",
      skor9: "",
      skor10: "",
      skor11: "",
      value7: "",
      value8: "",
      value9: "",
      value10: "",
      value11: "",
      /// /////////////////////end data//////////////////////////////
      disableRating: true,
      disableRating2: true,
      total_score1: null,
      total_score2: null,
      isModalAddVisible: false,
      modalShow: false,
      approved_at_core: '',
      approved_sv_at_core: null,
      agree_at_core: null,
      approved_hod_at_core: null,
      approved_hr_at_core: null,
      approved_at_bhv: '',
      approved_sv_at_bhv: null,
      agree_at_bhv: null,
      approved_hod_at_bhv: null,
      approved_hr_at_bhv: null,
      grade_set_id: null,
      subsidiary_id: null,
      core_weightage: null,
      behaviour_weightage: null,
      performance_id: this.$route.params.performance_id,
      isSupervisor: null,
      rating: [],
      isApproveSHR_core: false,
      isApproveHOD_core: false,
      isApproveHR_core: false,
      isApproveSV_core: false,
      isApproveSHR_bhv: false,
      isApproveHOD_bhv: false,
      isApproveHR_bhv: false,
      isApproveSV_bhv: false,
    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  mounted() {
    this.getScoreRating()
    this.getCore()
    this.getBehaviour()
    this.checkSupervisorStatus()
    this.currentUser()
  },
  methods: {
    getScoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll`).then(response => {
        this.rating = response.data.data
      })
    },
    currentUser(){
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/supervisor/getEmployee?user_id=${this.id}`, data).then(response => {
        // console.log('res', response.data.data)
        this.subsidiary_id = response.data.data.employee.business_unit
        this.grade_set_id = response.data.data.grade_set_id
        // console.log(this.subsidiary_id, this.grade_set_id)
        this.weightage()
      })
    },
    weightage(){
      const params = new URLSearchParams()
      params.append('subsidiary_id', this.subsidiary_id)
      params.append('role_id', this.grade_set_id)

      this.$axios.get(`${this.$baseUrl}/weightage/get_role_percentage`, { params }).then(response => {
        // console.log(response)
        this.core_weightage = response.data.data.core_competency
        this.behaviour_weightage = response.data.data.behavioural_competency
      })
    },
    checkSupervisorStatus(){
      this.$axios.get(`${this.$baseUrl}/supervisor/checkSupervisory?performance_id=${this.performance_id}&user_id=${this.userDetails.user_id}`).then(response => {
        this.isSupervisor = response.data.data.status.is_supervisor
        // this.isAppraiserSv = response.data.data.status.is_appraiser_sv
        // console.log("ini sv ke", this.isSupervisor)
        // console.log("ini appraiser sv ke", this.isAppraiserSv)
      })
    },
    selectCore(){
      this.getCore()
    },

    AssignBehavior() {
      const data = new FormData()
      data.append("user_id", this.id)
      this.selected.forEach(element => {
        data.append("behaviour_id[]", element)
      })

      this.$axios
        .post(`${this.$baseUrl}/myperformances/assign_performance_behaviour`, data)
        .then(() => {
          this.getBehaviour()
          setTimeout(() => {
            this.modalShow = false
          }, 1000)
        })
    },
    close() {
      this.modalShow = false
    },
    toggleInput() {
      this.disableRating = !this.disableRating
    },
    toggleInput2() {
      this.disableRating2 = !this.disableRating2
    },
    getCore() {
      const data = new FormData()
      // params.append("user_id", this.id);

      this.$axios
        .get(
          `${this.$baseUrl}/myperformances/competency_core?user_id=${this.id}`, data,
        )
        .then(response => {
          if (response.data.success === true) {
            // console.log('core', response)
            this.submit_at_core = response.data.data.submit_at
            this.competency_core_performances_id = response.data.data.active_competency_core_performance[0].id
            // console.log('user id core', this.id)
            // console.log('selfscore', response.data.data.active_competency_core_performance[0].self_score)
            const jobData0 = response.data.data.active_competency_core_performance[0]
            const jobData1 = response.data.data.active_competency_core_performance[1]
            const jobData2 = response.data.data.active_competency_core_performance[2]
            const jobData3 = response.data.data.active_competency_core_performance[3]
            const jobData4 = response.data.data.active_competency_core_performance[4]
            const jobData5 = response.data.data.active_competency_core_performance[5]

            this.name0 = jobData0.subcore
            this.name1 = jobData1.subcore
            this.name2 = jobData2.subcore
            this.name3 = jobData3.subcore
            this.name4 = jobData4.subcore
            this.name5 = jobData5.subcore

            this.skor1 = jobData0.self_score
            this.skor2 = jobData1.self_score
            this.skor3 = jobData2.self_score
            this.skor4 = jobData3.self_score
            this.skor5 = jobData4.self_score
            this.skor6 = jobData5.self_score

            this.value1 = jobData0.manager_score
            this.value2 = jobData1.manager_score
            this.value3 = jobData2.manager_score
            this.value4 = jobData3.manager_score
            this.value5 = jobData4.manager_score
            this.value6 = jobData5.manager_score

            this.description0 = jobData0.subcore
            this.description1 = jobData1.subcore
            this.description2 = jobData2.subcore
            this.description3 = jobData3.subcore
            this.description4 = jobData4.subcore
            this.description5 = jobData5.subcore

            this.core1 = jobData0.core_id
            this.core2 = jobData2.core_id
            this.core3 = jobData4.core_id
            this.subcore1_1 = jobData0.subcore_id
            this.subcore1_2 = jobData1.subcore_id
            this.subcore2_1 = jobData2.subcore_id
            this.subcore2_2 = jobData3.subcore_id
            this.subcore3_1 = jobData4.subcore_id
            this.subcore3_2 = jobData5.subcore_id

            this.corename1 = jobData0.core
            this.corename2 = jobData2.core
            this.corename3 = jobData4.core

            this.total_score1 = response.data.data.total_manager_score

            this.approved_at_core = response.data.data.approved_at // SHR approved
            this.approved_sv_at_core = response.data.data.approved_sv_at // SV approved
            this.agree_at_core = response.data.data.agree_at // Employee Agree
            this.approved_hod_at_core = response.data.data.approved_hod_at // HOD approved
            this.approved_hr_at_core = response.data.data.approved_hr_at // HR approved

            switch (this.roles.selectedRole){
              case 1:
                // Admin
                // console.log('admin')
                if (this.agree_at_core !== null && this.approved_at_core === null){
                  // console.log('masuk admin')
                  this.isApproveSHR_core = true
                }
                break
              case 3:
              // Executive
                // console.log('Executive')
                if (this.agree_at_core === null){
                  // console.log('masuk exec')
                  this.isApproveSV_core = true
                }
                break
              case 4:
              // Top Management
                // console.log('Top Management')
                if (this.agree_at_core === null){
                  // console.log('masuk tm')
                  this.isApproveSV_core = true
                }

                break
              case 5:
              // Head of Department
                // console.log('Head of Department')
                if (this.agree_at_core !== null && this.approved_hod_at_core === null){
                  // console.log('masuk hod')
                  this.isApproveHOD_core = true
                }
                break
              case 6:
                // Human Resource
                // console.log('Human Resource')
                if (this.agree_at_core !== null && this.approved_hr_at_core === null){
                  // console.log('masuk hr')
                  this.isApproveHR_core = true
                }
                break
              default:
                break
            }
          } else {
            // this.$errorConfirmation()
            this.errorConfirmation("danger")
          }
        })
    },
    getBehaviour() {
      const data = new FormData()
      this.$axios
        .get(`${this.$baseUrl}/myperformances/competency_behaviour?user_id=${this.id}`, data)
        .then(response => {
          // console.log('res', response)
          if (response.data.success === true) {
            this.submit_at_behavior = response.data.data.submit_at

            const jobData0 = response.data.data.active_competency_behaviour_performance[0]
            const jobData1 = response.data.data.active_competency_behaviour_performance[1]
            const jobData2 = response.data.data.active_competency_behaviour_performance[2]
            const jobData3 = response.data.data.active_competency_behaviour_performance[3]
            const jobData4 = response.data.data.active_competency_behaviour_performance[4]
            this.name6 = jobData0.competency_behaviour
            this.name7 = jobData1.competency_behaviour
            this.name8 = jobData2.competency_behaviour
            this.name9 = jobData3.competency_behaviour
            this.name10 = jobData4.competency_behaviour

            this.skor7 = jobData0.self_score
            this.skor8 = jobData1.self_score
            this.skor9 = jobData2.self_score
            this.skor10 = jobData3.self_score
            this.skor11 = jobData4.self_score

            this.value7 = jobData0.manager_score
            this.value8 = jobData1.manager_score
            this.value9 = jobData2.manager_score
            this.value10 = jobData3.manager_score
            this.value11 = jobData4.manager_score

            for (
              let i = 0;
              i < jobData0.competency_behaviour.subbehaviour.length;
              i += 1
            ) {
              this.description6[i] = jobData0.competency_behaviour.subbehaviour[i]
            }
            for (
              let i = 0;
              i < jobData1.competency_behaviour.subbehaviour.length;
              i += 1
            ) {
              this.description7[i] = jobData1.competency_behaviour.subbehaviour[i]
            }
            for (
              let i = 0;
              i < jobData2.competency_behaviour.subbehaviour.length;
              i += 1
            ) {
              this.description8[i] = jobData2.competency_behaviour.subbehaviour[i]
            }
            for (
              let i = 0;
              i < jobData3.competency_behaviour.subbehaviour.length;
              i += 1
            ) {
              this.description9[i] = jobData3.competency_behaviour.subbehaviour[i]
            }
            for (
              let i = 0;
              i < jobData4.competency_behaviour.subbehaviour.length;
              i += 1
            ) {
              this.description10[i] = jobData4.competency_behaviour.subbehaviour[i]
            }

            this.behaviour_id1 = jobData0.behaviour_id
            this.behaviour_id2 = jobData1.behaviour_id
            this.behaviour_id3 = jobData2.behaviour_id
            this.behaviour_id4 = jobData3.behaviour_id
            this.behaviour_id5 = jobData4.behaviour_id

            this.total_score2 = response.data.data.total_manager_score

            this.approved_at_bhv = response.data.data.approved_at // SHR Approved
            this.approved_sv_at_bhv = response.data.data.approved_sv_at // SV Approved
            this.agree_at_bhv = response.data.data.agree_at // Employee Agree
            this.approved_hod_at_bhv = response.data.data.approved_hod_at // HOD Approved
            this.approved_hr_at_bhv = response.data.data.approved_hr_at // HR approved

            switch (this.roles.selectedRole){
              case 1:
                // Admin
                // console.log('admin')
                if (this.agree_at_bhv !== null && this.approved_at_bhv === null){
                  // console.log('masuk admin')
                  this.isApproveSHR_bhv = true
                }
                break
              case 3:
              // Executive
                // console.log('Executive')
                if (this.agree_at_bhv === null){
                  // console.log('masuk exec')
                  this.isApproveSV_bhv = true
                }
                break
              case 4:
              // Top Management
                // console.log('Top Management')
                if (this.agree_at_bhv === null){
                  // console.log('masuk tm')
                  this.isApproveSV_bhv = true
                }
                break
              case 5:
              // Head of Department
                // console.log('Head of Department')
                if (this.agree_at_bhv !== null && this.approved_hod_at_bhv === null){
                  // console.log('masuk hod')
                  this.isApproveHOD_bhv = true
                }
                break
              case 6:
                // Human Resource
                // console.log('Human Resource')
                if (this.agree_at_bhv !== null && this.approved_hr_at_bhv === null){
                  // console.log('masuk hod')
                  this.isApproveHR_bhv = true
                }
                break
              default:
                break
            }
          } else {
            this.errorConfirmation("danger")
          }
        })
    },
    getBehaviorModal() {
      this.$axios
        .get(
          `${this.$baseUrl}/myperformances/get_performance_behaviour`,
        )
        .then(response => {
          this.behaviourData = response.data.data
        })
    },
    ManagerScoreCore() {
      const data = new FormData()
      data.append("user_id", this.id)
      data.append(`manager_score[${this.core1}][${this.subcore1_1}]`, this.value1)
      data.append(`manager_score[${this.core1}][${this.subcore1_2}]`, this.value2)
      data.append(`manager_score[${this.core2}][${this.subcore2_1}]`, this.value3)
      data.append(`manager_score[${this.core2}][${this.subcore2_2}]`, this.value4)
      data.append(`manager_score[${this.core3}][${this.subcore3_1}]`, this.value5)
      data.append(`manager_score[${this.core3}][${this.subcore3_2}]`, this.value6)
      // data.append("file", this.file)
      // console.log("manager score", this.jd_id)
      data.append(`current_role`, this.roles.selectedRole)

      this.$axios
        .post(`${this.$baseUrl}/manager/competency_core_manager_score_store`, data)
        .then(() => {
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Manager score core successfully saved')}.`,
                icon: "CheckCircleIcon",
                variant: "success",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
          this.getCore()
        })
    },
    ManagerScoreBehaviour() {
      const data = new FormData()
      data.append("user_id", this.id)
      data.append(`manager_score[${this.behaviour_id1}]`, this.value7)
      data.append(`manager_score[${this.behaviour_id2}]`, this.value8)
      data.append(`manager_score[${this.behaviour_id3}]`, this.value9)
      data.append(`manager_score[${this.behaviour_id4}]`, this.value10)
      data.append(`manager_score[${this.behaviour_id5}]`, this.value11)
      // data.append("file", this.file)
      // console.log("manager score", this.jd_id)
      data.append(`current_role`, this.roles.selectedRole)

      this.$axios
        .post(`${this.$baseUrl}/manager/competency_behaviour_manager_score_store`, data)
        .then(() => {
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Manager score behaviour successfully saved')}.`,
                icon: "CheckCircleIcon",
                variant: "success",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )

          this.getBehaviour()
        })
    },
    showModalAdd(id) {
      this.$axios
        .get(
          `${this.$baseUrl}/myperformances/get_performance_subcore?competency_core_id=${id}`,
        )
        .then(response => {
          this.subcoreList = response.data.data.subcore
          this.coreName = response.data.data.core.name
          this.coreDescription = response.data.data.core.description
          this.isModalAddVisible = true
        })
    },
    closeModalAdd() {
      this.isModalAddVisible = false
    },
  },
}
</script>
<style>
.bahagian {
  font-size: 22px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #000000;
}
.bahagian2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #000000;
  text-align: end !important;
}
.tab-title {
  font-size: 22px;
}
.kandungan {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #000000;
  text-align: start;
}
.core {
  font-size: 20px;
  font-family: "Poppins";
  font-style: normal;
  letter-spacing: 0.4px;
  color: #000000;
}

ul.a {
  list-style-position: outside;
}
.modal-behaviour {
  width: 20vw !important;
}
</style>
