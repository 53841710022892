<template>
  <div class="mt-3">
    <b-card style="border-radius: 20px;">
      <b-card-header>
        <span
          class="saj-title"
          style="

            line-height: 30px;
            letter-spacing: 0.4px;
            color: #000000;
          "
        >
          {{ $t("Part") }} 1: {{ $t("Overall Task Assessment") }}. {{ $t("Completed by members and discussed with Assessor") }}
        </span>
      </b-card-header>
      <hr>
      <div
        v-if="existitems1.length !== 0"
        class="m-0 mt-2"
      >
        <span
          class="saj-title mt-3 mb-3 mr-2 d-flex justify-content-end"
          style="
            line-height: 30px;
            letter-spacing: 0.4px;
            color: #000000;
          "
        >
          {{ total_score }}/{{ kra_nonExec_weightage }} %
        </span>
        <div v-if="submit_at_main !== null && (roles.selectedRole === 1 || ((roles.selectedRole === 3 || (roles.selectedRole === 4 && (!division || !section))) && isSupervisor === 1) || roles.selectedRole === 5 || roles.selectedRole === 6)">
          <b-col style="display: flex; justify-content: flex-end">
            <b-button
              class="m-0 mr-1 saj-button"
              style="

              background: #0b103c !important;
              border-color: #0b103c !important;
            "
              :style="isApproveSV || isApproveHOD || isApproveHR || isApproveSHR ? '' : 'display: none;'"
              @click="ManagerScore(), toggleInput1()"
            >
              {{ $t("Save") }}
            </b-button>
            <modalK1
              v-show="isModalVisible"
              @close="closeModal"
            />
          </b-col>
        </div>
        <b-card-body>
          <b-form
            ref="form"
            class="repeater-form"
            :disabled="disableInput11"
            @submit.prevent="repeateAgain"
          >
            <div
              v-for="(existitem1, i) in existitems1"
              :id="existitem1.id"
              :key="'b' + i"
              ref="row"
              class="m-0 mb-2 p-2"
              style="
            background: #f7f7fc;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px 0px 41px 9px rgba(0, 0, 0, 0.11);
             border-radius: 20px;
            height: 100%;
          "
            >
              <span
                class="saj-title"
                style="
                line-height: 24px;
                letter-spacing: 0.4px;
                color: #000000;
              "
              >
                {{ i + 1 + '.' }} {{ $t("Main Task") }} {{ $t(items.idd) }}
                <!-- {{ $t("Key Result Area") }} -->
              </span>
              <div class="m-0 mt-1 mb-1">
                <b-row>
                  <b-col cols="11">
                    <b-form-group label-for="textBox">
                      <div class="m-0 mb-1">
                        <b-form-textarea
                          v-model="existitem1.main_task_field"
                          disabled
                          class="saj-text"
                        />
                      </div>
                      <div class="m-0 mb-1 saj-title">
                        <span
                          style="
                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #000000;
                          "
                        >
                          {{ $t("Target") }}
                        </span>
                        <b-form-textarea
                          v-model="existitem1.target"
                          class="m-0 mt-1 saj-text"
                          disabled
                        />
                      </div>
                      <div class="m-0 mb-1 saj-title">
                        <span
                          style="
                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #000000;
                          "
                        >
                          {{ $t("Year End Achievements") }}
                        </span>
                        <b-form-textarea
                          v-model="existitem1.yearly_achievement"
                          class="m-0 mt-1 saj-text"
                          disabled
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col
                    cols="1"
                    class="d-flex justify-content-center align-items-center mb-1"
                  >
                    <feather-icon
                      style="
                      width: 30px;
                      height: 30px;
                      color: #c5c7cd;
                      margin-right: 20px;
                    "
                      icon="PlusCircleIcon"
                      @click="repeateAgain"
                    />
                  </b-col> -->
                  <!-- star -->
                </b-row>
              </div>
              <b-row
                class="
                  d-flex
                  justify-content-start
                  align-items-center
                  m-0
                  ml-2
                  mb-1
                  saj-title
                "
                style="

                  line-height: 24px;
                  letter-spacing: 0.1em;
                  color: #000000;
                  width: 100%;
                "
              >
                <div
                  v-if="existitem1.file === null"
                  class="row align-items-center"
                  style="font-size: 12px"
                >
                  <feather-icon
                    class="mr-1"
                    icon="PaperclipIcon"
                    size="16"
                  />
                  {{ $t("No attachment") }}
                </div>
                <div
                  v-if="existitem1.file !== null"
                  class="row align-items-center"
                  style="font-size: 12px"
                >
                  <feather-icon
                    class="mr-1"
                    icon="PaperclipIcon"
                    size="16"
                  />
                  <a
                    :href="existitem1.file.url"
                    target="_blank"
                  >
                    {{ $t(existitem1.file.name) }}
                  </a>
                </div>
              </b-row>
              <div
                class="d-flex justify-content-start saj-title"
                style="
                  line-height: 24px;
                  letter-spacing: 0.1em;
                  color: #000000;
                "
              >
                {{ $t("Self Score") }}: {{ existitem1.self_score }}/6
              </div>
              <b-row class="mb-2">
                <b-col
                  cols="8"
                  class="d-flex align-items-center saj-title"
                  style="
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: #000000;
                  "
                >
                  {{ $t("Manager Score") }}:

                  <b-form-rating
                    v-model="existitem1.value1"
                    show-clear
                    no-border
                    show-value
                    inline
                    color="#DFB300"
                    stars="6"
                    size="lg"
                    :disabled="disableRating1"
                  />
                  <span class="m-1">
                    <h5 v-if="existitem1.value1 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                    <h5 v-else-if="existitem1.value1 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                    <h5 v-else-if="existitem1.value1 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                    <h5 v-else-if="existitem1.value1 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                    <h5 v-else-if="existitem1.value1 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                    <h5 v-else-if="existitem1.value1 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                  </span>
                </b-col>
                <div v-if="submit_at_main !== null && (roles.selectedRole === 1 || ((roles.selectedRole === 3 || (roles.selectedRole === 4 && (!division || !section) )) && isSupervisor === 1) || roles.selectedRole === 5 || roles.selectedRole === 6)">
                  <b-col cols="4">
                    <div class="d-flex justify-content-center m-0 ml-4">
                      <b-button
                        class="m-0 saj-button"
                        variant="primary"
                        :style="isApproveSV || isApproveHOD || isApproveHR || isApproveSHR ? '' : 'display: none;'"
                        @click="toggleInput1()"
                      >
                        {{ $t("Edit") }}
                      </b-button>
                    </div>
                  </b-col>
                </div>
              </b-row>
            </div>
          </b-form></b-card-body>
      </div>
      <div
        v-else
        class="saj-text d-flex align-items-center justify-content-center bg-light p-1 m-0 mb-3 mr-1"
        style="font-style: italic; background: rgb(247, 247, 252); text-align: center; height: 200px; border-radius:20px "
      >

        {{ $t('Key Result Area are not available') }}

      </div>
      <hr>
      <div
        v-if="existitems2.length !== 0"
        class="m-0 mt-2"
      >
        <b-col style="display: flex; justify-content: flex-end">
          <div v-if="submit_at_side !== null && (roles.selectedRole === 1 || ((roles.selectedRole === 3 || roles.selectedRole === 4) && isSupervisor === 1) || roles.selectedRole === 5 || roles.selectedRole === 6)">
            <b-button
              class="m-0 mr-1 saj-button"
              style="
              background: #0b103c !important;
              border-color: #0b103c !important;
            "
              :style="isApproveSV || isApproveHOD || isApproveHR || isApproveSHR ? '' : 'display: none;'"
              @click="ManagerScore2(), toggleInput3()"
            >
              {{ $t("Save") }}
            </b-button>
          </div>
          <modalK1
            v-show="isModalVisible"
            @close="closeModal"
          />
        </b-col>
        <b-card-body>
          <b-form
            ref="form"
            class="repeater-form"
            :disabled="disableInput22"
            @submit.prevent="repeateAgain2"
          >
            <div
              v-for="(existitem2, i) in existitems2"
              :id="existitem2.id"
              :key="'b' + i"
              ref="row"
              class="m-0 mb-2 p-2"
              style="
            background: #f7f7fc;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px 0px 41px 9px rgba(0, 0, 0, 0.11);
             border-radius: 20px;
            height: 100%;
          "
            >
              <span
                class="saj-title"
                style="

                line-height: 24px;
                letter-spacing: 0.4px;
                color: #000000;
              "
              >
                {{ i + 1 + '.' }} {{ $t("Summary of additional tasks carried out throughout the year") }}
                <!-- Ringkasan tugasan sampingan yang dilaksanakan sepanjang tahun {{ items.idd }} -->
              </span>
              <div class="m-0 mt-1 mb-1">
                <b-row>
                  <b-col cols="11">
                    <b-form-group label-for="textBox">
                      <div class="m-0 mb-1">
                        <b-form-textarea
                          v-model="existitem2.side_task_summary"
                          class="saj-text"
                          disabled
                        />
                      </div>
                      <div class="m-0 mb-1 saj-title">
                        <span
                          style="
                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #000000;
                          "
                        >
                          {{ $t("Target") }}
                        </span>
                        <b-form-textarea
                          v-model="existitem2.target"
                          class="m-0 mt-1 saj-text"
                          disabled
                        />
                      </div>
                      <div class="m-0 mb-1 saj-title">
                        <span
                          style="
                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #000000;
                          "
                        >
                          {{ $t("Year End Achievements") }}
                        </span>
                        <b-form-textarea
                          v-model="existitem2.yearly_achievement"
                          class="m-0 mt-1 saj-text"
                          disabled
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col
                    cols="1"
                    class="d-flex justify-content-center align-items-center mb-1"
                  >
                    <feather-icon
                      style="
                      width: 30px;
                      height: 30px;
                      color: #c5c7cd;
                      margin-right: 20px;
                    "
                      icon="PlusCircleIcon"
                      @click="repeateAgain2"
                    />
                  </b-col> -->
                  <!-- star -->
                </b-row>
              </div>
              <b-row
                class="
                  d-flex
                  justify-content-start
                  align-items-center
                  m-0
                  ml-2
                  mb-1
                  saj-title
                "
                style="
                  line-height: 24px;
                  letter-spacing: 0.1em;
                  color: #000000;
                  width: 100%;
                "
              >
                <div
                  v-if="existitem2.file === null"
                  class="row align-items-center"
                  style="font-size: 12px"
                >
                  <feather-icon
                    class="mr-1"
                    icon="PaperclipIcon"
                    size="16"
                  />
                  {{ $t("No attachment") }}
                </div>
                <div
                  v-if="existitem2.file !== null"
                  class="row align-items-center"
                  style="font-size: 12px"
                >
                  <feather-icon
                    class="mr-1"
                    icon="PaperclipIcon"
                    size="16"
                  />
                  <a
                    :href="existitem2.file.url"
                    target="_blank"
                  >
                    {{ $t(existitem2.file.name) }}
                  </a>
                </div>
              </b-row>
              <div
                class="d-flex justify-content-start saj-title"
                style="
                  line-height: 24px;
                  letter-spacing: 0.1em;
                  color: #000000;
                "
              >
                {{ $t("Self Score") }}: {{ existitem2.self_score }}/6
              </div>
              <b-row class="mb-2">
                <b-col
                  cols="8"
                  class="saj-title d-flex align-items-center"
                  style="
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: #000000;
                  "
                >
                  {{ $t("Manager Score") }}:

                  <b-form-rating
                    v-model="existitem2.value2"
                    show-clear
                    no-border
                    show-value
                    inline
                    color="#DFB300"
                    stars="6"
                    size="lg"
                    :disabled="disableRating3"
                  />
                  <span class="m-1">
                    <h5 v-if="existitem2.value2 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                    <h5 v-else-if="existitem2.value2 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                    <h5 v-else-if="existitem2.value2 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                    <h5 v-else-if="existitem2.value2 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                    <h5 v-else-if="existitem2.value2 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                    <h5 v-else-if="existitem2.value2 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                  </span>
                </b-col>

                <div v-if="submit_at_side !== null && (roles.selectedRole === 1 || ((roles.selectedRole === 3 || (roles.selectedRole === 4 && (!division || !section) )) && isSupervisor === 1) || roles.selectedRole === 5 || roles.selectedRole === 6)">
                  <b-col cols="4">
                    <div class="d-flex justify-content-center m-0 ml-4">
                      <b-button
                        class="m-0 saj-button"
                        variant="primary"
                        :style="isApproveSV || isApproveHOD || isApproveHR || isApproveSHR ? '' : 'display: none;'"
                        @click="toggleInput3()"
                      >
                        {{ $t("Edit") }}
                      </b-button>
                    </div>
                  </b-col>
                </div>
              </b-row>
            </div>
          </b-form></b-card-body>
      </div>
      <div
        v-else
        class="saj-text d-flex align-items-center justify-content-center bg-light p-1 m-0 mb-3 mr-1"
        style="font-style: italic; background: rgb(247, 247, 252); text-align: center; height: 200px; border-radius:20px "
      >

        {{ $t('Summary of additional tasks not available') }}

      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormTextarea,
  BButton,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormRating,
  BForm,
  //   BContainer,
  //   BImg,
  //   BTabs,
  //   BTab,
  //   BFormInput,
  BFormGroup,
  //   BFormSelect,
  //   BFormDatepicker,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import Initiative from '@/pages/non-exec/KRA/Part-1.2.vue'
// import axios from "axios"
// import { heightTransition } from "@core/mixins/ui/transition"
import modalK1 from "@/component/saveModal.vue"
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Initiative,
    // BButton,
    // BButtonGroup,
    // BNav,
    // BNavItem,
    // BLink,
    BCard,
    BCardHeader,
    BCardBody,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BFormRating,
    BForm,
    // BContainer,
    // BImg,
    // BTabs,
    // BTab,
    // BFormInput,
    BFormGroup,
    // BFormSelect,
    // BFormDatepicker,
    modalK1,
  },
  directives: {
    Ripple,
  },
  props: {
    division: {
      type: null,
      default: null,
    },
    section: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      user: "",
      i: "",
      id: this.$route.params.id,
      performance_id: this.$route.params.performance_id,
      isSupervisor: null,
      isAppraiserSv: null,
      total_score: null,
      submit_at_main: null,
      submit_at_side: null,
      items: [
        {
          idd: 1,
          prevHeight: 0,
          main_task_field: "",
          target: "",
          yearly_achievement: "",
          self_score: "",
          id: "",
          dbs_id: "",
          // total_score: null,
        },
      ],
      items2: [
        {
          idd: 1,
          prevHeight: 0,
          side_task_summary: "",
          target: "",
          yearly_achievement: "",
          self_score: "",
          id: "",
          dbs_id: "",
          // total_score: null,
        },
      ],
      existitems1: [],
      existitems2: [],
      existItems: [
        // {
        //   idd: 1,
        //   prevHeight: 0,
        //   content: "",
        //   target: "",
        //   measurement: "",
        //   self_score: "",
        //   id: "",
        //   // dbs_id: "",
        // },
      ],
      nextTodoId: 2,
      nextTodoId2: 2,
      disableInput1: true,
      disableInput2: true,
      disableInput11: true,
      disableInput22: true,
      disableInput3: true,
      disableInput4: true,
      disableRating1: true,
      disableRating2: true,
      disableRating3: true,
      disableRating4: true,
      isModalVisible: false,
      rating: [],
      approved_at_main: '',
      approved_sv_at_main: null,
      agree_at_main: null,
      approved_hod_at_main: null,
      approved_hr_at_main: null,
      approved_at_side: '',
      approved_sv_at_side: null,
      agree_at_side: null,
      approved_hod_at_side: null,
      approved_hr_at_side: null,
      isApproveSHR: false,
      isApproveHOD: false,
      isApproveHR: false,
      isApproveSV: false,
      subsidiary_id: null,
      grade_set_id: null,
      kra_nonExec_weightage: null,
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
    ...mapGetters(['roles', 'userDetails']),
  },
  mounted() {
    this.getScoreRating()
    this.getData()
    this.getData2()
    this.currentUser()
    this.checkSupervisorStatus()
    // console.log('user performance id', this.performance_id)
    // console.log('section', this.section)
  },
  methods: {
    getScoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll`).then(response => {
        this.rating = response.data.data
      })
    },
    currentUser(){
      const data = new FormData()
      // data.append("user_id", this.idCore)
      this.$axios.get(`${this.$baseUrl}/supervisor/getEmployee?user_id=${this.id}`, data).then(response => {
        this.user = response.data.data.role_name
        this.subsidiary_id = response.data.data.employee.business_unit
        this.grade_set_id = response.data.data.grade_set_id
        // console.log(this.subsidiary_id, this.grade_set_id)
        // console.log("role", this.user)
        this.weightage()
      })
    },
    weightage(){
      const params = new URLSearchParams()
      params.append('subsidiary_id', this.subsidiary_id)
      params.append('role_id', this.grade_set_id)

      this.$axios.get(`${this.$baseUrl}/weightage/get_role_percentage`, { params }).then(response => {
        // console.log(response)
        this.kra_nonExec_weightage = response.data.data.overall_assesment
      })
    },
    checkSupervisorStatus(){
      this.$axios.get(`${this.$baseUrl}/supervisor/checkSupervisory?performance_id=${this.performance_id}&user_id=${this.userDetails.user_id}`).then(response => {
        this.isSupervisor = response.data.data.status.is_supervisor
        this.isAppraiserSv = response.data.data.status.is_appraiser_sv
        // console.log("ini sv ke", this.isSupervisor)
        // console.log("ini appraiser sv ke", this.isAppraiserSv)
      })
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      })
    },
    repeateAgain2() {
      this.items2.push({
        id: (this.nextTodoId2 += this.nextTodoId2),
      })
    },
    toggleInput1() {
      this.disableInput11 = !this.disableInput11
      this.disableInput1 = !this.disableInput1
      this.disableRating1 = !this.disableRating1
    },
    toggleInput2() {
      this.disableInput11 = !this.disableInput11
      this.disableInput2 = !this.disableInput2
      this.disableRating2 = !this.disableRating2
    },
    toggleInput3() {
      this.disableInput22 = !this.disableInput22
      this.disableInput3 = !this.disableInput3
      this.disableRating3 = !this.disableRating3
    },
    toggleInput4() {
      this.disableInput22 = !this.disableInput22
      this.disableInput4 = !this.disableInput4
      this.disableRating4 = !this.disableRating4
    },
    getData() {
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/myperformances/main_task?user_id=${this.id}`, data).then(response => {
        // console.log('main', response)
        this.submit_at_main = response.data.data.submit_at
        if (response.data.data.main_task.length > 0) {
          for (let i = 0; i < response.data.data.main_task.length; i += 1) {
            this.existitems1.push({
              id: response.data.data.main_task[i].id,
              main_task_field: response.data.data.main_task[i].main_task_field,
              target: response.data.data.main_task[i].target,
              yearly_achievement: response.data.data.main_task[i].yearly_achievement,
              self_score: response.data.data.main_task[i].marks,
              value1: response.data.data.main_task[i].manager_marks,
              file: response.data.data.main_task[i].file,
              fileToUpload: null,
            })
          }
        }
        this.total_score = response.data.data.total_score

        this.approved_at_main = response.data.data.approved_at // SHR approved
        this.approved_sv_at_main = response.data.data.approved_sv_at // SV approved
        this.agree_at_main = response.data.data.agree_at // Employee Agree
        this.approved_hod_at_main = response.data.data.approved_hod_at // HOD approved
        this.approved_hr_at_main = response.data.data.approved_hr_at // HR approved

        switch (this.roles.selectedRole){
          case 1:
            // Admin
            // console.log('admin')
            if (this.agree_at_main !== null && this.approved_at_main === null){
              // console.log('masuk admin')
              this.isApproveSHR = true
            }
            break
          case 3:
            // Executive
            // console.log('Executive')
            if (this.agree_at_main === null){
              // console.log('masuk exec')
              this.isApproveSV = true
            }
            break
          case 4:
            // Top Management
            // console.log('Top Management')
            if (this.agree_at_main === null){
              // console.log('masuk tm')
              this.isApproveSV = true
            }
            break
          case 5:
            // Head of Department
            // console.log('Head of Department')
            if (this.agree_at_main !== null && this.approved_hod_at_main === null){
              // console.log('masuk hod')
              this.isApproveHOD = true
            }
            break
          case 6:
            // Human Resource
            // console.log('Human Resource')
            if (this.agree_at_main !== null && this.approved_hr_at_main === null){
              // console.log('masuk hr')
              this.isApproveHR = true
            }
            break
          default:
            break
        }
      })
    },
    getData2() {
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/myperformances/side_task?user_id=${this.id}`, data).then(response => {
        // console.log('side', response)
        this.submit_at_side = response.data.data.submit_at
        if (response.data.data.side_task.length > 0) {
          for (let i = 0; i < response.data.data.side_task.length; i += 1) {
            this.existitems2.push({
              id: response.data.data.side_task[i].id,
              side_task_summary: response.data.data.side_task[i].side_task_summary,
              target: response.data.data.side_task[i].target,
              yearly_achievement: response.data.data.side_task[i].yearly_achievement,
              self_score: response.data.data.side_task[i].marks,
              value2: response.data.data.side_task[i].manager_marks,
              file: response.data.data.side_task[i].file,
              fileToUpload: null,
            })
          }
        }
        this.approved_at_side = response.data.data.approved_at // SHR approved
        this.approved_sv_at_side = response.data.data.approved_sv_at // SV approved
        this.agree_at_side = response.data.data.agree_at // Employee Agree
        this.approved_hod_at_side = response.data.data.approved_hod_at // HOD approved
        this.approved_hr_at_side = response.data.data.approved_hr_at // HR approved

        switch (this.roles.selectedRole){
          case 1:
            // Admin
            // console.log('admin')
            if (this.agree_at_side !== null && this.approved_at_side === null){
              // console.log('masuk admin')
              this.isApproveSHR = true
            }
            break
          case 3:
            // Executive
            // console.log('Executive')
            if (this.agree_at_side === null){
              // console.log('masuk exec')
              this.isApproveSV = true
            }
            break
          case 4:
            // Top Management
            // console.log('Top Management')
            if (this.agree_at_side === null){
              // console.log('masuk tm')
              this.isApproveSV = true
            }
            break
          case 5:
            // Head of Department
            // console.log('Head of Department')
            if (this.agree_at_side !== null && this.approved_hod_at_side === null){
              // console.log('masuk hod')
              this.isApproveHOD = true
            }
            break
          case 6:
            // Human Resource
            // console.log('Human Resource')
            if (this.agree_at_side !== null && this.approved_hr_at === null){
              // console.log('masuk hr')
              this.isApproveHR = true
            }
            break
          default:
            break
        }
      })
    },
    ManagerScore() {
      const data = new FormData()
      data.append("user_id", this.id)
      let i = 0
      for (i; i < this.existitems1.length; i += 1) {
        const idd = this.existitems1[i].id
        // console.log("sini check", this.existitems)
        data.append(`main_task_id[${i}]`, idd)
        data.append(`manager_marks[${idd}]`, this.existitems1[i].value1)
      }
      data.append(`current_role`, this.roles.selectedRole)
      // console.log("ini manager score", this.val.planning.dbs_manager_score)
      this.$axios
        .post(`${this.$baseUrl}/manager/main_task_score_store`, data)
        .then(() => {
          setTimeout(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Manager Score successfully updated')}.`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
            // this.close()
            this.$emit('update-performance')
          }, 1000)
        })
    },
    ManagerScore2() {
      const data = new FormData()
      data.append("user_id", this.id)
      let i = 0
      for (i; i < this.existitems2.length; i += 1) {
        const idd = this.existitems2[i].id
        // console.log("sini check", this.existitems)
        data.append(`side_task_id[${i}]`, idd)
        data.append(`manager_marks[${idd}]`, this.existitems2[i].value2)
      }
      data.append(`current_role`, this.roles.selectedRole)
      // console.log("ini manager score", this.val.planning.dbs_manager_score)
      this.$axios
        .post(`${this.$baseUrl}/manager/side_task_score_store`, data)
        .then(() => {
          setTimeout(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Manager Score successfully updated')}.`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
            // this.close()
            this.$emit('update-performance')
          }, 1000)
        })
    },
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
  },

}
</script>
