<template>
  <div class="mt-3">
    <section id="card-navigation">
      <b-row>
        <!-- pill tabs inside card -->

        <b-col md="12">
          <b-card
            class=""
            style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
              min-height: 80rem;
            "
          >
            <b-card-header>
              <span
                class="saj-title m-0"
                style="
                  font-family: Poppins;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 30px;
                  letter-spacing: 0.4px;
                  color: #000000;
                "
              >
                {{ $t(" Part 4") }}:
                {{ $t("Overall Performance Comments") }}-
                {{ $t("Please complete at the end of the Performance Appraisal") }}.
              </span>
            </b-card-header>

            <hr>
            <div
              nav-class="mb-3"
              style="
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.1em;
              "
            >
              <div title="">
                <b-col
                  class="saj-title"
                  style="
                      margin-bottom: 10px;
                      display: flex;
                      justify-content: flex-end;
                    "
                >
                  <div v-if="submit_at !== null && ((roles.isTopManagement && (!division || !section)) || roles.isExecutive || roles.isHOD || roles.isHR)">
                    <!-- Save for SV -->
                    <b-button
                      v-if="isSupervisor === 1"
                      variant="primary"
                      :style="isApproveSV ? '' : 'display: none;'"
                      style="margin-right: 10px"
                      class="saj-button"
                      @click="toggleInput"
                    >
                      {{ $t("Edit") }}
                    </b-button>
                    <b-button
                      v-if="isSupervisor === 1"
                      class="saj-button"
                      style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                        margin-right: 10px;
                      "
                      :style="isApproveSV ? '' : 'display: none;'"
                      @click="updateComment()"
                    >
                      {{ $t("Save") }}
                    </b-button>
                    <!-- Save for Appraiser SV -->
                    <b-button
                      v-if="isAppraiserHOD === 1 || isAppraiserHR === 1"
                      variant="primary"
                      :style="isApproveHOD || isApproveHR ? '' : 'display: none;'"
                      style="margin-right: 10px"
                      class="saj-button"
                      @click="toggleInput"
                    >
                      {{ $t("Edit") }}
                    </b-button>
                    <b-button
                      v-if="isAppraiserHOD === 1 || isAppraiserHR === 1"
                      class="saj-button"
                      style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                        margin-right: 10px;
                      "
                      :style="isApproveHOD || isApproveHR ? '' : 'display: none;'"
                      @click="isAppraiserHOD === 1 || isAppraiserHR === 1 ? updateCommentSV() : updateComment()"
                    >
                      {{ $t("Save") }}
                    </b-button>
                    <!-- Approve Button SV -->
                    <b-button
                      v-if="isSupervisor === 1"
                      v-b-modal.approve-performance
                      variant="primary"
                      :disabled="submit === 0 || agree === 1 || approved_sv === 1 "
                      class="saj-button"
                      :style="isApproveSV ? '' : 'display: none;'"
                    >
                      {{ approved_sv === 1 ? $t("Approved") : $t("Approve") }}
                    </b-button>
                    <!-- Approve Button for HR -->
                    <b-button
                      v-if="isAppraiserHR === 1"
                      v-b-modal.approve-performance
                      variant="primary"
                      class="saj-button"
                      :style="isApproveHOD || isApproveHR ? '' : 'display: none;'"
                    >
                      {{ $t("Approve") }}
                    </b-button>
                  </div>
                </b-col>
                <!-- Display Appraisee Comment -->
                <b-col>
                  <span
                    class="saj-title m-0 ml-2"
                    style="
                        font-family: 'Poppins';
                        line-height: 30px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                  >
                    {{ $t("COMMENT BY APPRAISEE") }}</span>
                  <b-card
                    class="mt-1 mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <textarea
                      v-model="comment"
                      class="saj-text form-control flex-grow-1"
                      name="textarea"
                      disabled
                      style="height: 150px
                            border: 1px solid #d8d6de;
                            border-radius: 0.750rem;
                            "
                      rows="5"
                    />
                    <b-row class="mt-1">
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div>{{ $t("Appraisee Name") }}: {{ $t(appraiser_name) }}</div>
                          <div> {{ $t("Designation") }}: {{ $t(appraiser_position) }}</div>
                        </div>
                      </b-col>

                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >

                          <div class="saj-text">
                            {{ $t("Date") }}: {{ $t(DateChanged(tarikh)) }}
                          </div>

                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- Display Appraisee Comment End -->
                <!-- FOR SV VIEW -->
                <b-col v-if="(roles.selectedRole === 3 || roles.selectedRole === 4) && isSupervisor === 1">
                  <span
                    class="saj-title m-0 ml-2"
                    style="
                        font-family: 'Poppins';
                        line-height: 30px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                  >
                    {{ $t("COMMENTS BY APPRAISER") }}</span>
                  <b-card
                    class="mt-1 mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 80%;
                      "
                  >
                    <!-- card 1 inside-->
                    <div class="m-0 mt-1 mb-2 d-flex flex-column">
                      <textarea
                        v-model="comment2"
                        class="saj-text form-control flex-grow-1"
                        name="textarea"
                        :disabled="disableInput"
                        style="height: 150px
                              border: 1px solid #d8d6de;
                              border-radius: 0.750rem;
                              "
                        rows="5"
                      />

                    </div>
                    <b-row>
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div>{{ $t("Supervisor Name") }}: {{ $t(sv_name) }}</div>
                          <div>{{ $t("Designation") }}: {{ $t(sv_position) }}</div>
                        </div>
                      </b-col>
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div>
                            {{ $t("Previous Comments") }}:
                            <feather-icon
                              v-if="previous_comment === 1"
                              v-b-tooltip.hover.bottom="'Previous'"
                              style="
                                 width: 30px;
                                  height: 30px;
                                  color: #000000;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                              icon="ChevronLeftIcon"
                              @click="getAppraiserComment('previous')"
                            />
                            <feather-icon
                              v-else
                              style="
                                 width: 30px;
                                  height: 30px;
                                  color:  #A9A9A9;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                              icon="ChevronLeftIcon"
                            />
                            {{ $t(komen) }}
                            <feather-icon
                              v-if="next_comment === 1"
                              v-b-tooltip.hover.bottom="'Next'"
                              style="
                                   width: 30px;
                                  height: 30px;
                                  color: #000000;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                              icon="ChevronRightIcon"
                              @click="getAppraiserComment('next')"
                            />
                            <feather-icon
                              v-else
                              style="
                                   width: 30px;
                                  height: 30px;
                                  color:  #A9A9A9;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                              icon="ChevronRightIcon"
                            />
                          </div>
                          <div class="saj-text">
                            {{ $t("Date") }}: {{ $t(DateChanged(tarikh2)) }}
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <div
                      class="saj-text"
                      style="
                          font-family: 'Poppins';
                          font-weight: 400;
                          letter-spacing: 0.15px;
                        "
                    />
                  </b-card>
                </b-col>
                <b-col v-if="(roles.selectedRole === 3 || roles.selectedRole === 4) && isSupervisor === 1">
                  <span
                    class="saj-title m-0 ml-2"
                    style="
                        font-family: 'Poppins';
                        line-height: 30px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                  >
                    {{ $t("COMMENTS BY APPRAISER'S SUPERVISOR") }} / {{ $t("SUPERVISOR") }} /
                    {{ $t("HEAD OF DEPARTMENT") }} / {{ $t("CEO") }}/
                    {{ $t("COO") }}/ {{ $t("CHAIRMAN") }}
                    <!-- login sv -->
                  </span>
                  <b-card
                    class="mt-1 mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <textarea
                      v-model="comment3"
                      class="saj-text form-control flex-grow-1"
                      name="textarea"
                      disabled
                      style="height: 150px
                            border: 1px solid #d8d6de;
                            border-radius: 0.750rem;
                            "
                      rows="5"
                    />
                    <!-- <b-card
                      type="text"
                      style="
                          background: #efefef;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 150px;
                        "
                    >
                      <b-row style="height: 108px">
                        <b-col
                          cols="12"
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              line-height: 30px;
                              letter-spacing: 0.4px;
                              color: ##a9a9a9; ;
                            "
                        >{{ $t(comment3) }}</b-col>
                      </b-row>
                    </b-card> -->
                    <b-row class="mt-1">
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div>
                            {{ $t("Appraiser's Supervisor Name") }}: {{ $t(hod_data === null ? appraiser_hr_name : appraiser_hod_name) }} </div>
                          <div>{{ $t("Designation") }}: {{ $t(hod_data === null ? appraiser_hr_position : appraiser_hod_position) }}</div>
                        </div>
                      </b-col>
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div> {{ $t("Date") }}: {{ $t(DateChanged(tarikh3)) }}</div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- FOR SV VIEW END-->

                <!-- FOR Appraiser SV VIEW -->
                <b-col v-if="((roles.selectedRole === 3 || roles.selectedRole === 4) && isAppraiserSv === 1) || (roles.selectedRole === 5 && isAppraiserHOD === 1) || (roles.selectedRole === 6 && isAppraiserHR === 1)">
                  <span
                    class="saj-title m-0 ml-2"
                    style="
                        font-family: 'Poppins';
                        line-height: 30px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                  >
                    {{ $t("COMMENTS BY APPRAISER") }}</span>
                  <b-card
                    class="mt-1 mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 80%;
                      "
                  >
                    <div class="m-0 mt-1 mb-2 d-flex flex-column">
                      <textarea
                        v-model="comment2"
                        class="saj-text form-control flex-grow-1"
                        name="textarea"
                        :disabled="((roles.selectedRole === 3 || roles.selectedRole === 4) && isAppraiserSv === 1 || (roles.selectedRole === 5 && isAppraiserHOD === 1) || (roles.selectedRole === 6 && isAppraiserHR === 1)) || disableInput"
                        style="height: 150px
                              border: 1px solid #d8d6de;
                              border-radius: 0.750rem;
                              "
                        rows="5"
                      />
                    </div>
                    <b-row>
                      <b-col cols="6">
                        <div
                          v-if="(roles.selectedRole === 3 || roles.selectedRole === 4) && isAppraiserSv === 1 || (roles.selectedRole === 5 && isAppraiserHOD === 1) || (roles.selectedRole === 6 && isAppraiserHR === 1)"
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div>{{ $t("Supervisor Name") }}:{{ supervisor === null ? '-' : supervisor.full_name }}</div>
                          <div>{{ $t("Designation") }}: {{ supervisor === null ? '-' : supervisor.position }}</div>
                        </div>
                        <div
                          v-if="(roles.selectedRole === 3 || roles.selectedRole === 4) && isSupervisor === 1"
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div>{{ $t("Supervisor Name") }}: {{ $t(sv_name) }}</div>
                          <div>{{ $t("Designation") }}: {{ $t(sv_position) }}</div>
                        </div>
                      </b-col>
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div>
                            {{ $t("Previous Comments") }}:
                            <feather-icon
                              v-if="previous_comment === 1"
                              v-b-tooltip.hover.bottom="'Previous'"
                              style="
                                 width: 30px;
                                  height: 30px;
                                  color: #000000;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                              icon="ChevronLeftIcon"
                              @click="getAppraiserComment('previous')"
                            />
                            <feather-icon
                              v-else
                              style="
                                 width: 30px;
                                  height: 30px;
                                  color:  #A9A9A9;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                              icon="ChevronLeftIcon"
                            />
                            {{ $t(komen) }}
                            <feather-icon
                              v-if="next_comment === 1"
                              v-b-tooltip.hover.bottom="'Next'"
                              style="
                                   width: 30px;
                                  height: 30px;
                                  color: #000000;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                              icon="ChevronRightIcon"
                              @click="getAppraiserComment('next')"
                            />
                            <feather-icon
                              v-else
                              style="
                                   width: 30px;
                                  height: 30px;
                                  color:  #A9A9A9;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                              icon="ChevronRightIcon"
                            />
                          </div>
                          <div class="saj-text">
                            {{ $t("Date") }}: {{ $t(DateChanged(tarikh2)) }}
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <div
                      class="saj-text"
                      style="
                          font-family: 'Poppins';
                          font-weight: 400;
                          letter-spacing: 0.15px;
                        "
                    />
                  </b-card>
                </b-col>
                <b-col v-if="((roles.selectedRole === 3 || roles.selectedRole === 4) && isAppraiserSv === 1) || (roles.selectedRole === 5 && isAppraiserHOD === 1) || (roles.selectedRole === 6 && isAppraiserHR === 1)">
                  <span
                    class="saj-title m-0 ml-2"
                    style="
                        font-family: 'Poppins';
                        line-height: 30px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                  >
                    {{ $t("COMMENTS BY APPRAISER'S SUPERVISOR") }} / {{ $t("SUPERVISOR") }} /
                    {{ $t("HEAD OF DEPARTMENT") }} / {{ $t("CEO") }}/
                    {{ $t("COO") }}/ {{ $t("CHAIRMAN") }}
                    <!-- login app sv -->
                  </span>
                  <b-card
                    class="mt-1 mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 80%;
                      "
                  >
                    <!-- card 1 inside-->
                    <div class="m-0 mt-1 mb-2 d-flex flex-column">
                      <textarea
                        v-model="comment4"
                        class="saj-text form-control flex-grow-1"
                        name="textarea"
                        :disabled="((roles.selectedRole === 3 || roles.selectedRole === 4) && isSupervisor === 1) || disableInput"
                        style="height: 150px
                              border: 1px solid #d8d6de;
                              border-radius: 0.750rem;
                              "
                        rows="5"
                      />
                    </div>
                    <b-row>
                      <b-col cols="6">
                        <!-- user Top management -->
                        <div
                          v-if="((roles.selectedRole === 3 || roles.selectedRole === 4) && isAppraiserSv === 1) || (roles.selectedRole === 5 && isAppraiserHOD === 1) || (roles.selectedRole === 6 && isAppraiserHR === 1)"
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div>{{ $t("Supervisor Name") }}:{{ hod_data === null ? appraiser_hr_name : appraiser_hod_name }}</div>
                          <div>{{ $t("Designation") }}: {{ hod_data === null ? appraiser_hr_position : appraiser_hod_position }}</div>
                          <!-- <div>{{ $t("Supervisor Name") }}: {{ $t(sv_name) }}</div>
                          <div>{{ $t("Designation") }}: {{ $t(sv_position) }}</div> -->
                        </div>
                        <!-- user Top management end -->
                        <!-- user exec bawah-->
                        <div
                          v-if="(roles.selectedRole === 3 || roles.selectedRole === 4) && isSupervisor === 1"
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >
                          <div>{{ $t("Supervisor Name") }}: {{ $t(sv_name) }}</div>
                          <div>{{ $t("Designation") }}: {{ $t(sv_position) }}</div>
                        </div>
                        <!-- user exec end -->
                      </b-col>
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              letter-spacing: 0.15px;
                            "
                        >

                          <div class="saj-text">
                            {{ $t("Date") }}: {{ $t(DateChanged(tarikh3)) }}
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <div
                      class="saj-text"
                      style="
                          font-family: 'Poppins';
                          font-weight: 400;
                          letter-spacing: 0.15px;
                        "
                    >
                      <!-- <div>Nama Penyelia : {{ nama }}</div>
                        <div>Jawatan : {{ jawatan }}</div> -->
                    </div>
                  </b-card>
                </b-col>
                <!-- FOR Appraiser SV VIEW ENDS -->

                <!-- FOR HOD/HR/SHR/SECTION/DIVISION VIEW -->
                <b-col v-if="isAppraiserSv === 0 && isSupervisor === 0 && isAppraiserHOD === 0 && isAppraiserHR === 0">
                  <span
                    class="saj-title m-0 ml-2"
                    style="
        font-family: 'Poppins';
        line-height: 30px;
        letter-spacing: 0.4px;
        color: #000000;
      "
                  >
                    {{ $t("COMMENTS BY APPRAISER") }}</span>
                  <b-card
                    class="mt-1 mb-2"
                    style="
        background: #f7f7fc;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
        border-radius: 20px;
        height: 80%;
      "
                  >
                    <div class="m-0 mt-1 mb-2 d-flex flex-column">
                      <textarea
                        v-model="comment2"
                        class="saj-text form-control flex-grow-1"
                        name="textarea"
                        :disabled="disableInput"
                        style="height: 150px
              border: 1px solid #d8d6de;
              border-radius: 0.750rem;
              "
                        rows="5"
                      />
                    </div>
                    <b-row>
                      <b-col cols="6">
                        <div
                          v-if="isAppraiserSv === 0 && isSupervisor === 0 && isAppraiserHOD === 0 && isAppraiserHR === 0"
                          class="saj-text"
                          style="
              font-family: 'Poppins';
              font-weight: 400;
              letter-spacing: 0.15px;
            "
                        >
                          <div>{{ $t("Supervisor Name") }}:{{ supervisor === null ? '-' : supervisor.full_name }}</div>
                          <div>{{ $t("Designation") }}: {{ supervisor === null ? '-' : supervisor.position }}</div>
                        </div>
                        <!-- <div
                          v-if="isSupervisor === 0"
                          class="saj-text"
                          style="
              font-family: 'Poppins';
              font-weight: 400;
              letter-spacing: 0.15px;
            "
                        >
                          <div>{{ $t("Supervisor Name") }}: {{ $t(sv_name) }}</div>
                          <div>{{ $t("Designation") }}: {{ $t(sv_position) }}</div>
                        </div> -->
                      </b-col>
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
              font-family: 'Poppins';
              font-weight: 400;
              letter-spacing: 0.15px;
            "
                        >
                          <div>
                            {{ $t("Previous Comments") }}:
                            <feather-icon
                              v-if="previous_comment === 1"
                              v-b-tooltip.hover.bottom="'Previous'"
                              style="
                 width: 30px;
                  height: 30px;
                  color: #000000;
                  margin-right: 20px;
                  cursor: pointer;
                "
                              icon="ChevronLeftIcon"
                              @click="getAppraiserComment('previous')"
                            />
                            <feather-icon
                              v-else
                              style="
                 width: 30px;
                  height: 30px;
                  color:  #A9A9A9;
                  margin-right: 20px;
                  cursor: pointer;
                "
                              icon="ChevronLeftIcon"
                            />
                            {{ $t(komen) }}
                            <feather-icon
                              v-if="next_comment === 1"
                              v-b-tooltip.hover.bottom="'Next'"
                              style="
                   width: 30px;
                  height: 30px;
                  color: #000000;
                  margin-right: 20px;
                  cursor: pointer;
                "
                              icon="ChevronRightIcon"
                              @click="getAppraiserComment('next')"
                            />
                            <feather-icon
                              v-else
                              style="
                   width: 30px;
                  height: 30px;
                  color:  #A9A9A9;
                  margin-right: 20px;
                  cursor: pointer;
                "
                              icon="ChevronRightIcon"
                            />
                          </div>
                          <div class="saj-text">
                            {{ $t("Date") }}: {{ $t(DateChanged(tarikh2)) }}
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <div
                      class="saj-text"
                      style="
          font-family: 'Poppins';
          font-weight: 400;
          letter-spacing: 0.15px;
        "
                    />
                  </b-card>
                </b-col>
                <b-col v-if="isAppraiserSv === 0 && isSupervisor === 0 && isAppraiserHOD === 0 && isAppraiserHR === 0">
                  <span
                    class="saj-title m-0 ml-2"
                    style="
        font-family: 'Poppins';
        line-height: 30px;
        letter-spacing: 0.4px;
        color: #000000;
      "
                  >
                    {{ $t("COMMENTS BY APPRAISER'S SUPERVISOR") }} / {{ $t("SUPERVISOR") }} /
                    {{ $t("HEAD OF DEPARTMENT") }} / {{ $t("CEO") }}/
                    {{ $t("COO") }}/ {{ $t("CHAIRMAN") }}
                  </span>
                  <b-card
                    class="mt-1 mb-2"
                    style="
        background: #f7f7fc;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
        border-radius: 20px;
        height: 80%;
      "
                  >
                    <div class="m-0 mt-1 mb-2 d-flex flex-column">
                      <textarea
                        v-model="comment4"
                        class="saj-text form-control flex-grow-1"
                        name="textarea"
                        :disabled="disableInput"
                        style="height: 150px
              border: 1px solid #d8d6de;
              border-radius: 0.750rem;
              "
                        rows="5"
                      />
                    </div>
                    <b-row>
                      <b-col cols="6">
                        <div
                          v-if="isAppraiserSv === 0 && isSupervisor === 0 && isAppraiserHOD === 0 && isAppraiserHR === 0"
                          class="saj-text"
                          style="
              font-family: 'Poppins';
              font-weight: 400;
              letter-spacing: 0.15px;
            "
                        >
                          <div>{{ $t("Supervisor Name") }}:{{ hod_data === null ? appraiser_hr_name : appraiser_hod_name }}</div>
                          <div>{{ $t("Designation") }}: {{ hod_data === null ? appraiser_hr_position : appraiser_hod_position }}</div>
                        </div>
                      </b-col>
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
              font-family: 'Poppins';
              font-weight: 400;
              letter-spacing: 0.15px;
            "
                        >

                          <div class="saj-text">
                            {{ $t("Date") }}: {{ $t(DateChanged(tarikh3)) }}
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <div
                      class="saj-text"
                      style="
          font-family: 'Poppins';
          font-weight: 400;
          letter-spacing: 0.15px;
        "
                    />
                  </b-card>
                </b-col>
                <!-- FOR FOR HOD/HR/SHR/SECTION/DIVISION VIEW ENDS -->

                <hr class="mb-3 mt-3">
                <!-- Display Suggestion List -->

                <b-col>
                  <span
                    class="saj-title m-0 ml-2"
                    style="
                        font-family: 'Poppins';
                        line-height: 16px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                  >
                    {{ $t("PROPOSED INDIVIDUAL TRAINING AND DEVELOPMENT NEEDS") }}</span>

                </b-col>

                <!-- Add Suggestion -->
                <b-col>
                  <b-card
                    class="mt-1 mb-2"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 80%;
                      "
                  >
                    <b-col
                      v-if="submit_at !== null && (!division || !section)"
                      style="
                      display: flex;
                      justify-content: flex-end;
                    "
                    >
                      <b-button
                        v-if="isSupervisor === 1 || !isAppraiserSv === 1 || !isAppraiserHOD === 1"
                        variant="primary"
                        class="saj-button"
                        style="margin-right: 10px"
                        :style="isApproveSV ? '' : 'display: none;'"
                        @click="toggleInput3()"
                      >
                        {{ $t("Edit") }}
                      </b-button>
                      <template v-if="isSupervisor === 1 || !isAppraiserSv === 1 || !isAppraiserHOD === 1">
                        <b-button
                          v-if="isEmptySuggestion"
                          class="saj-button"
                          style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                          :style="isApproveSV ? '' : 'display: none;'"
                          @click="createSuggestion(),toggleInput3()"
                        >
                          {{ $t("Save") }}
                        </b-button>
                        <b-button
                          v-else
                          class="saj-button"
                          style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                          :style="isApproveSV ? '' : 'display: none;'"
                          @click="updateSuggestion(),toggleInput3()"
                        >
                          {{ $t("Save") }}
                        </b-button>
                      </template></b-col>
                    <div class="d-flex justify-content-between">
                      <span
                        class="saj-title"
                        style="
                letter-spacing: 0.4px;
                color: #000000;
              "
                      >
                        {{ $t("Add Suggestion") }}:
                      </span>
                    </div>
                    <!-- card 1 inside-->
                    <div class="m-0 mt-1 mb-2 d-flex flex-column">
                      <textarea
                        v-model="addSuggestion"
                        class="saj-text form-control flex-grow-1"
                        name="textarea"
                        :disabled="disableInput3"
                        style="height: 150px
                              border: 1px solid #d8d6de;
                              border-radius: 0.750rem;"
                        rows="5"
                      />
                    </div>
                    <b-row>
                      <b-col cols="6">
                        <!-- <div
                            style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              font-size: 21px;
                              letter-spacing: 0.15px;
                            "
                          >
                            <div>Appraisee Name : {{ svname }}</div>
                            <div>Designation  : {{ jawatan }}</div>
                          </div> -->
                      </b-col>
                      <b-col cols="6">
                        <div
                          class="saj-text"
                          style="
                              letter-spacing: 0.15px;
                            "
                        />
                      </b-col>
                    </b-row>
                    <div
                      class="saj-text"
                      style="
                          letter-spacing: 0.15px;
                        "
                    >
                      <!-- <div>Nama Penyelia : {{ nama }}</div>
                        <div>Jawatan : {{ jawatan }}</div> -->
                    </div>
                  </b-card>
                </b-col>
              </div>
            </div>
            <b-modal
              id="approve-performance"
              :hide-footer="true"
              :hide-header="true"
              :centered="true"
            >
              <approve-perf
                :title="$t('Are you sure to approve this supervisee’s mark?')"
                @cancel="$bvModal.hide('approve-performance')"
                @confirm="approvePerformance()"
              />
            </b-modal>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  // BContainer,
  // BImg,
  BButton,
  // BFormTextarea,
  // BTabs,
  // BTab,
  // BFormInput,
  // BFormGroup,
  // BFormSelect,
  // BFormDatepicker,
  VBTooltip,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
// import axios from "axios"
import SAJToast from "@/component/saj-toastification.vue"
import { mapGetters } from 'vuex'
import moment from "moment"
import approvePerf from "@/pages/alert-modal.vue"

export default {
  components: {
    approvePerf,
    BRow,
    BCol,
    BCard,
    // BContainer,
    // BImg,
    BButton,
    BCardHeader,
    // BFormTextarea,
    // BTabs,
    // BTab,
    // BFormInput,
    // BFormGroup,
    // BFormSelect,
    // // vSelect,
    // BFormDatepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    division: {
      type: null,
      default: null,
    },
    section: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      performance_approval: null,
      submit: null,
      agree: null,
      approved_sv: null,
      approved_hr: null,
      submit_at: null,
      // userRole: "",
      userID: "",
      isEmptySuggestion: false,
      tarikh: "",
      tarikh2: "",
      tarikh3: "",
      tarikh4: "",
      komen: "",
      performance_id: this.$route.params.performance_id,
      id: this.$route.params.id,
      comment: "",
      comment2: "",
      comment3: "",
      comment4: "",
      suggestion: "",
      appraiser_name: "",
      appraiser_position: "",
      sv_name: "",
      sv_position: "",
      hod_data: null,
      appraiser_hod_name: "",
      appraiser_hod_position: "",
      appraiser_hr_name: "",
      appraiser_hr_position: "",
      proposal: "",
      disableInput: true,
      disableInput2: true,
      disableInput3: true,
      existSuggests: [],
      addSuggestion: "",
      suggestion_id: "",
      performanceID: null,
      appraiser_comment: null,
      next_comment: 0,
      previous_comment: 0,
      commentUser: "",
      isSupervisor: null,
      isAppraiserSv: null,
      isAppraiserHOD: null,
      isAppraiserHR: null,
      approved_at: '',
      approved_sv_at: null,
      agree_at: null,
      approved_hod_at: null,
      approved_hr_at: null,
      isApproveSV: false,
      isApproveHOD: false,
      isApproveHR: false,
      supervisor: null,
    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
    // filterProducts1() {
    //   return this.filteredRole(this.filteredName(this.items1))
    // },
  },
  mounted() {
    this.getData()
    this.getDataUser()
    this.getSuggestion()
    this.getCommentappraiserSV()
    this.userPerformance()
    // this.getStaffinfo()
    // console.log('current user id', this.userDetails.user_id)
  },
  methods: {
    DateChanged(date) {
      if (date !== '-' && date !== '') {
        const newDay = moment(date, "").format("D-MM-yyyy")
        return newDay
      }
      return '-'
    },
    // currentUser(){
    //   const data = new FormData()
    //   this.$axios.get(`${this.$baseUrl}/supervisor/getEmployee?user_id=${this.userID}`, data).then(response => {
    //     this.userRole = response.data.data.role_name
    //   })
    // },

    leftIcon() {

    },
    rightIcon() {

    },
    getData() {
      // const params = new FormData()
      // params.append("performance_id", this.performance_id)
      this.$axios.get(`${this.$baseUrl}/myperformances/appraiser_comment?performance_id=${this.performance_id}`).then(response => {
        // console.log(response)
        // this.userIdComment = response.data.data.appraisee.
        // console.log("check data comment", response)
        this.performanceID = response.data.data.id
        this.userID = response.data.data.user_id
        this.submit_at = response.data.data.submit_at

        this.getAppraiserComment()
        // this.currentUser()
        this.getDataUser()
        this.getCommentappraiserSV()
        this.checkSupervisorStatus()

        if (response.data.data.comment_performance !== null) {
          this.comment = response.data.data.comment_performance.comment !== null ? response.data.data.comment_performance.comment : `${this.$t('No Comment')}`
          // this.suggestion = response.data.data.comment_performance.suggestion
          this.tarikh = response.data.data.comment_performance.updated_at.slice(0, 10) !== null ? response.data.data.comment_performance.updated_at.slice(0, 10) : '-'
        } else {
          // Kalau takde langsung
          this.comment = `${this.$t('No Comment')}`
          this.tarikh = '-'
        }
        if (response.data.data.appraiser_sv_hod_comment !== null) {
          this.comment3 = response.data.data.appraiser_sv_hod_comment.comment !== null ? response.data.data.appraiser_sv_hod_comment.comment : `${this.$t('No Comment')}`
          this.tarikh3 = response.data.data.appraiser_sv_hod_comment.updated_at.slice(0, 10) !== null ? response.data.data.appraiser_sv_hod_comment.updated_at.slice(0, 10) : '-'
        } else {
          this.comment3 = `${this.$t('No Comment')}`
          this.tarikh3 = '-'
        }
        this.appraiser_name = response.data.data.appraisee.full_name
        this.appraiser_position = response.data.data.appraisee.position
        this.sv_name = response.data.data.supervisor === null || response.data.data.supervisor === undefined ? '-' : response.data.data.supervisor.full_name
        this.sv_position = response.data.data.supervisor === null || response.data.data.supervisor === undefined ? '-' : response.data.data.supervisor.position

        this.hod_data = response.data.data.appraiser_sv_hod
        if (this.hod_data !== null) {
          this.appraiser_hod_name = this.hod_data.full_name
          this.appraiser_hod_position = this.hod_data.position
        }

        this.appraiser_hr_name = response.data.data.appraiser_hod_hr.full_name
        this.appraiser_hr_position = response.data.data.appraiser_hod_hr.position

        this.approved_at = response.data.data.approved_at
        this.approved_sv_at = response.data.data.approved_sv_at
        this.agree_at = response.data.data.agree_at
        this.approved_hod_at = response.data.data.approved_hod_at
        this.approved_hr_at = response.data.data.approved_hr_at

        switch (this.roles.selectedRole){
          case 3:
            // Executive
            // console.log('Executive')
            if (this.agree_at === null){
              // console.log('masuk exec')
              this.isApproveSV = true
            }
            break
          case 4:
            // Top Management
            // console.log('Top Management')
            if (this.agree_at === null){
              // console.log('masuk tm')
              this.isApproveSV = true
            }
            break
          case 5:
            // Head of Department
            // console.log('Head of Department')
            if (this.agree_at !== null && this.approved_hod_at === null){
              // console.log('masuk hod')
              this.isApproveHOD = true
            }
            break
          case 6:
            // Human Resource
            // console.log('Human Resource')
            if (this.agree_at !== null && this.approved_hr_at === null){
              // console.log('masuk hod')
              this.isApproveHR = true
            }
            break
          default:
            break
        }
      })
    },
    getDataUser() {
      this.$axios.get(`${this.$baseUrl}/myperformances/comment?user_id=${this.userID}&cycle_id=${this.performance_id}`).then(response => {
        // console.log("cari sv", response)
        this.commentUser = response.data.data
        this.supervisor = response.data.data.supervisor
      })
    },
    getCommentappraiserSV(){
      this.$axios.get(`${this.$baseUrl}/myperformances/appraiser_sv_comment?performance_id=${this.performance_id}`).then(response => {
        this.getAppraiserSVComment = response.data.data.appraiser_sv_comment
        // this.comment4 = this.getAppraiserSVComment.comment
        if (this.getAppraiserSVComment !== null){
          this.comment4 = this.getAppraiserSVComment.comment === null ? 'No Comment' : this.getAppraiserSVComment.comment
        }
      })
    },
    checkSupervisorStatus(){
      this.$axios.get(`${this.$baseUrl}/supervisor/checkSupervisory?performance_id=${this.performance_id}&user_id=${this.userDetails.user_id}`).then(response => {
        // console.log(response)
        this.isSupervisor = response.data.data.status.is_supervisor
        this.isAppraiserSv = response.data.data.status.is_appraiser_sv
        this.isAppraiserHOD = response.data.data.status.is_appraiser_hod
        this.isAppraiserHR = response.data.data.status.is_appraiser_hr
        // console.log("this is sv", this.isSupervisor)
        // console.log("this is appraiser sv", this.isAppraiserSv)
        // console.log("this is appraiser hod", this.isAppraiserHOD)
        // console.log("this is appraiser hr", this.isAppraiserHR)
      })
    },
    updateComment() {
      const data = new FormData()
      data.append("performance_id", this.performance_id)
      data.append(`comment`, this.comment2)
      this.$axios.post(`${this.$baseUrl}/myperformances/appraiser_comment`, data).then(() => {
        this.getData()
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Comment successfully updated and saved')}.`,
              icon: "CheckCircleIcon",
              variant: "success",
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        setTimeout(() => {
          this.disableInput = true
        }, 1000)
      })
    },
    updateCommentSV(){
      const data = new FormData()
      data.append("performance_id", this.performance_id)
      data.append(`comment`, this.comment4)
      this.$axios.post(`${this.$baseUrl}/myperformances/appraiser_sv_comment`, data).then(() => {
        this.getData()
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Comment successfully updated and saved')}.`,
              icon: "CheckCircleIcon",
              variant: "success",
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )

        setTimeout(() => {
          this.disableInput = true
        }, 1000)
      }).catch(() => {
        // console.log(error.response)
      })
    },
    createSuggestion() {
      const data = new FormData()
      data.append("performance_id", this.performance_id)
      data.append(`suggestion`, this.addSuggestion)
      this.$axios.post(`${this.$baseUrl}/suggestions/add`, data).then(() => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Suggestion(s) successfully saved')}.`,
              icon: "CheckCircleIcon",
              variant: "success",
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        // this.getSuggestion()
        setTimeout(() => {
          this.isEmptySuggestion = false
        }, 1000)
      })
    },
    updateSuggestion() {
      // data.append("suggestion_id", this.performance_id)
      // data.append(`suggestion`, this.addSuggestion)
      const data = new FormData()
      data.append(`suggestion_id`, this.suggestion_id)
      data.append(`suggestion`, this.addSuggestion)

      this.$axios.post(`${this.$baseUrl}/suggestions/update`, data).then(() => {
        // this.getSuggestion()
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Suggestion(s) successfully saved')}.`,
              icon: "CheckCircleIcon",
              variant: "success",
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        setTimeout(() => {
          this.disableInput3 = true
        }, 1000)
      })
    },
    getSuggestion(){
      // const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/suggestions/get_by_emp?user_id=${this.id}`).then(response => {
        this.res = response.data.data
        if (response.data.data.length !== 0) {
          this.addSuggestion = response.data.data[0].suggestion
          this.suggestion_id = response.data.data[0].id
        } else {
          this.isEmptySuggestion = true
          this.addSuggestion = '-'
        }
      })
    },
    getAppraiserComment(val){
      const data = new FormData()

      data.append('performance_id', this.performanceID)
      if (val === 'previous'){
        data.append('previous', 1)
      } else if (val === 'next'){
        data.append('next', 1)
      } else {
        // console.log('current')
      }

      this.$axios.post(`${this.$baseUrl}/myperformances/get_appraiser_comments`, data).then(response => {
        this.appraiser_comment = response.data.data
        if (this.appraiser_comment !== null){
          this.performanceID = this.appraiser_comment.performance_id
          this.comment2 = this.appraiser_comment.comment !== null ? this.appraiser_comment.comment : '-'
          this.tarikh2 = this.appraiser_comment.date !== null ? this.appraiser_comment.date.slice(0, 10) : '-'
        } else {
          // console.log('null')
        }

        this.next_comment = this.appraiser_comment === null ? '' : this.appraiser_comment.has_next
        this.previous_comment = this.appraiser_comment === null ? '' : this.appraiser_comment.has_previous
      })
      // .catch(error => {
      //   console.log('hello', error)
      // })
    },
    userPerformance(){
      this.$axios.get(`${this.$baseUrl}/myperformances/getUserPerformanceSet?user_id=${this.id}`).then(response => {
        // console.log('user perf', response)
        this.performance_approval = response.data.data.performance_approval
        this.submit = this.performance_approval.submit
        this.agree = this.performance_approval.agree
        this.approved_sv = this.performance_approval.approved_sv
        this.approved_hr = this.performance_approval.approved_hr
        // console.log("approve sv: ", this.performance_approval)
      })
    },
    approvePerformance(){
      const data = new FormData()
      if (this.isSupervisor === 1) {
        data.append(`sv_approve`, 1)
      }

      if (this.isAppraiserHR === 1) {
        data.append(`hr_approve`, 1)
      }

      data.append(`performance_id[]`, this.performance_id)
      this.$axios.post(`${this.$baseUrl}/performance/approve`, data).then(() => {
        setTimeout(() => {
          this.$bvModal.hide('approve-performance')
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Successfully approved')}!`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
          this.userPerformance()
        }, 1000)
      }).catch(() => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Marks has not been given')}.`,
              icon: 'XIcon',
              iconBg: '#e80202',
              variant: 'danger',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'error',
          },
        )
      })
    },
    toggleInput() {
      this.disableInput = !this.disableInput
    },
    toggleInput2() {
      this.disableInput2 = !this.disableInput2
    },
    toggleInput3() {
      this.disableInput3 = !this.disableInput3
    },
  },
}
</script>
