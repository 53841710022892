<template>
  <div class="mt-3">
    <section id="card-navigation">
      <b-row>

        <b-col md="12">
          <b-card
            class=""
            style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
            "
          >
            <b-card-header>
              <span
                class="saj-title m-0"
                style="
                  font-family: Poppins;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 30px;
                  letter-spacing: 0.4px;
                  color: #000000;
                "
              >
                {{ $t ("Summary Score") }}
              </span>
            </b-card-header>
            <hr>
            <div>
              <summaryscore-global
                :user-i-d="id"
                :performance-i-d="performance_id"
                :team-role="role"
                :gradeset="gradeset"
                :sub-id="subId"
              />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  VBTooltip,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
import { mapGetters } from 'vuex'
import moment from "moment"
import summaryscoreGlobal from "@/views/pages/summary/summary-score-global.vue"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    summaryscoreGlobal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      userRole: "",
      userID: "",
      performance_id: this.$route.params.performance_id.toString(),
      id: this.$route.params.id.toString(),
      proposal: "",
      disableInput: true,
      disableInput2: true,
      disableInput3: true,
      suggestion_id: "",
      performanceID: null,
    }
  },
  computed: {
    ...mapGetters(['roles']),
  },
  mounted() {
  },
  methods: {
    DateChanged(date) {
      if (date !== '-' && date !== '') {
        const newDay = moment(date, "").format("D-MM-yyyy")
        return newDay
      }
      return '-'
    },
    toggleInput() {
      this.disableInput = !this.disableInput
    },
    toggleInput2() {
      this.disableInput2 = !this.disableInput2
    },
    toggleInput3() {
      this.disableInput3 = !this.disableInput3
    },
  },
  props: {

    role: {
      type: Number,
      default: null,
    },
    gradeset: {
      type: Number,
      default: null,
    },
    subId: {
      type: Number,
      default: null,
    },
  },
}
</script>
